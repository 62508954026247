import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { getUserClientInformation } from '../../api';
import { capitalizeFirstLetter } from '../../utils/generic';
import { useTranslation } from 'react-i18next';
import SecondaryButton from '../Global/SecondaryButton';
import ErrorFallback from "../Global/ErrorFallback";

function ClientDetails({ onEdit }) {
    const { t } = useTranslation();
    const [userClientInformation, setUserClientInformation] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserClientInformation = async () => {
            setLoading(true);
            try {
                const data = await getUserClientInformation();
                setUserClientInformation(data);
            } catch (e) {
                setError(e);
            } finally {
                setLoading(false);
            }
        };

        fetchUserClientInformation();
    }, []);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress/>
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" alignItems="center" justifyContent="center" height="70vh">
                <ErrorFallback message={t("userInfo.errorFallback")}/>
            </Box>
        );
    }

    if (!userClientInformation) {

        return <CircularProgress/>;
    }

    const {
        firstName,
        lastName,
        phoneNumber,
        emailAddress,
        address: {
            street,
            buildingName,
            unit,
            city,
            administrativeArea,
            postCode,
            country
        } = {}
    } = userClientInformation;

    const formatAddress = () => {
        return [buildingName, `Unit ${unit}`, street, city, administrativeArea, postCode, country].filter(Boolean).join(', ');
    };

    return (
        <TableContainer component={Paper} elevation={3} sx={{ mt: 10, borderRadius: 2 }}>
            <Table sx={{ maxWidth: 650 }}>
                <TableBody>
                    {[
                        {
                            label: t('clientDetails.name'),
                            value: `${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(lastName)}`
                        },
                        { label: t('clientDetails.address'), value: formatAddress() },
                        { label: t('clientDetails.phoneNumber'), value: phoneNumber },
                        { label: t('clientDetails.email'), value: emailAddress },
                    ].map((row, index) => (
                        <TableRow key={index}>
                            <TableCell component="th" scope="row" sx={{ fontWeight: 'medium' }}>
                                {row.label}:
                            </TableCell>
                            <TableCell>{row.value}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Box display="flex" justifyContent="flex-end" m={2}>
                <SecondaryButton text={t('clientDetails.edit')} onClick={onEdit} />
            </Box>
        </TableContainer>
    );
}

export default ClientDetails;
