import React from 'react';
import { Box, IconButton } from '@mui/material';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const ImageViewer = ({ imageData, rotationAngle, onRotate }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Box sx={{ textAlign: 'center' }}>
            <img
                src={imageData}
                alt="Invoice"
                style={{
                    width: isSmallScreen ? "100vw" : "auto",
                    maxHeight: "90vh",
                    transform: `rotate(${rotationAngle}deg)`,
                    transition: "transform 0.3s ease"
                }}
                onContextMenu={(e) => e.preventDefault()}  // Prevent right-click
            />
            <Box mt={2}>
                <IconButton onClick={onRotate} aria-label="rotate">
                    <RotateRightIcon />
                </IconButton>
            </Box>
        </Box>
    );
};

export default ImageViewer;
