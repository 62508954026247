import React, {useState} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {useTranslation} from 'react-i18next';
import {postPasswordResetQuery} from '../../../../api';
import './PasswordResetQuery.css';
import PrimaryButton from "../../../Global/PrimaryButton";

const PasswordResetQuery = () => {
    const {t} = useTranslation();
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        setLoading(true);
        try {
            await postPasswordResetQuery(email.toLowerCase());
            setMessage(t('passwordResetQuery.successMessage'));
        } catch (error) {
            setMessage(t('passwordResetQuery.errorMessage'));
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="password-reset-form-container">
            <form className="password-reset-form">
                <h2>{t('passwordResetQuery.title')}</h2>
                <div className="login-form__group">
                    <label htmlFor="email" className="login-form__label">
                        {t('passwordResetQuery.emailLabel')}
                    </label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="login-form__input"
                        required
                    />
                </div>
                <PrimaryButton
                    text={loading ? null : t('passwordResetQuery.submitButton')}
                    sx={{width: "50%"}}
                    disabled={loading || !email}
                    onClick={() => handleSubmit()}
                    icon={loading ? <CircularProgress size={24}/> : null}
                />

                {message && <div className="password-form__message">{message}</div>}
            </form>
        </div>
    );
};

export default PasswordResetQuery;
