import React, { useState } from 'react';
import { Box, Paper, Typography, IconButton } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDropzone } from 'react-dropzone';
import heic2any from 'heic2any';
import { useTranslation } from "react-i18next";

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        width: '100%',
    },
    dropzone: {
        border: '2px dashed #cccccc',
        borderRadius: '8px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
        width: '100%',
        marginBottom: '20px',
    },
    fileListItem: {
        backgroundColor: '#f5f5f5',
        borderRadius: '4px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px',
        margin: '10px 0',
    },
    cloudUploadIcon: {
        fontSize: '4vh',
        color: '#666666',
    },
    previewImage: {
        maxWidth: '100%',
        maxHeight: '40vh',
        borderRadius: '8px',
        margin: '10px 0',
    },
};

const CustomDropzone = ({ onFileAccepted, maxFiles = 1, acceptedFiles }) => {
    const [uploadedFile, setUploadedFile] = useState(null);
    const [fileSizeError, setFileSizeError] = useState(null);
    const [fileTypeError, setFileTypeError] = useState(null);
    const { t } = useTranslation();

    // Ensure acceptedFiles prop is an array
    acceptedFiles = Array.isArray(acceptedFiles) ? acceptedFiles : [];

    // Valid MIME types
    const validMimeTypes = [
        'image/jpeg',
        'image/jpg',
        'image/png',
        'image/heic',
        'application/pdf',
    ];

    // Filter out invalid MIME types
    const filteredAcceptedFiles = acceptedFiles.filter(type => validMimeTypes.includes(type));

    // Use the filtered MIME types for the accept prop
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: async (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                const file = acceptedFiles[0];

                // Check file size
                if (file.size > 10 * 1024 * 1024) {
                    setFileSizeError(t("customDropzone.fileSizeError"));
                    setFileTypeError("");
                    return;
                }

                // Check file type
                if (!filteredAcceptedFiles.includes(file.type)) {
                    console.log(filteredAcceptedFiles);
                    console.log(file.type, filteredAcceptedFiles, validMimeTypes.includes(file.type));
                    setFileTypeError(t("customDropzone.invalidFileTypeError"));
                    setFileSizeError("");
                    return;
                }

                // Handle HEIC conversion
                if (file.type === "image/heic") {
                    const convertedBlob = await heic2any({
                        blob: file,
                        toType: "image/jpeg",
                        quality: 0.8
                    });
                    file.preview = URL.createObjectURL(convertedBlob);
                } else if (file.type.startsWith("image/")) {
                    file.preview = URL.createObjectURL(file);
                }

                onFileAccepted(file);
                setUploadedFile({
                    file,
                    name: file.name,
                    preview: file.preview,
                });
                setFileSizeError(null);
                setFileTypeError(null);
            }
        },
        accept: filteredAcceptedFiles.join(','), // Convert filtered MIME types to a comma-separated string
        maxSize: 10 * 1024 * 1024,  // 10 MB limit per file
        maxFiles: maxFiles,
        multiple: false,
        noClick: uploadedFile != null,  // Disable click if file already uploaded
        noKeyboard: uploadedFile != null,
    });

    const removeFile = () => {
        if (uploadedFile && uploadedFile.preview) {
            URL.revokeObjectURL(uploadedFile.preview);
        }
        setUploadedFile(null);
        onFileAccepted(null);
    };

    return (
        <Box sx={styles.container}>
            <Paper elevation={3} {...getRootProps()} sx={styles.dropzone}>
                <input {...getInputProps()} />
                {uploadedFile ? (
                    <>
                        <Box sx={styles.fileListItem}>
                            <Typography variant="body1">{uploadedFile.name}</Typography>
                            <IconButton onClick={removeFile} color="error">
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                        {uploadedFile.file.type.startsWith("image/") && (
                            <img src={uploadedFile.preview} alt="Preview" style={styles.previewImage} />
                        )}
                    </>
                ) : isDragActive ? (
                    <Typography variant="h6">
                        {t("customDropzone.instructionsDrop")}
                    </Typography>
                ) : (
                    <Box textAlign="center">
                        <CloudUploadIcon sx={styles.cloudUploadIcon} />
                        <Typography variant="h6">
                            {t("customDropzone.instructionsFull")}
                        </Typography>
                    </Box>
                )}
            </Paper>
            {fileSizeError && (
                <Typography color="error" sx={{ mt: 2 }}>
                    {fileSizeError}
                </Typography>
            )}
            {fileTypeError && (
                <Typography color="error" sx={{ mt: 2 }}>
                    {fileTypeError}
                </Typography>
            )}
        </Box>
    );
};

export default CustomDropzone;
