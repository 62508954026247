import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  POST_USER_CLIENT_REGISTRATION_SUCCESS,
} from './actionTypes';

export const authLogin = (userUid, userEmail, jwt, garageUid, isFullyOnboarded) => async (dispatch) => {
  dispatch({
    type: AUTH_LOGIN,
    userUid,
    userEmail,
    jwt,
    garageUid,
    isFullyOnboarded
  })
};

export const authLogout = () => async (dispatch) => {
  dispatch({
    type: AUTH_LOGOUT,
  })
};

export const updateRegistrationStatus = () => async (dispatch) => {
  dispatch({
    type: POST_USER_CLIENT_REGISTRATION_SUCCESS,
  })
};
