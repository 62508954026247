export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const POST_USER_CLIENT_REGISTRATION_SUCCESS = 'POST_USER_CLIENT_REGISTRATION_SUCCESS';

export const FETCH_VEHICLES_FROM_USER_REQUEST = 'FETCH_VEHICLES_FROM_USER_REQUEST';
export const FETCH_VEHICLES_FROM_USER_SUCCESS = 'FETCH_VEHICLES_FROM_USER_SUCCESS';
export const FETCH_VEHICLES_FROM_USER_FAILURE = 'FETCH_VEHICLES_FROM_USER_FAILURE';

export const FETCH_VEHICLE_DETAILS_REQUEST = 'FETCH_VEHICLE_DETAILS_REQUEST';
export const FETCH_VEHICLE_DETAILS_SUCCESS = 'FETCH_VEHICLE_DETAILS_SUCCESS';
export const FETCH_VEHICLE_DETAILS_FAILURE = 'FETCH_VEHICLE_DETAILS_FAILURE';

export const FETCH_USER_CLIENT_ONBOARDING_STATUS_REQUEST = 'FETCH_USER_CLIENT_ONBOARDING_STATUS_REQUEST';
export const FETCH_USER_CLIENT_ONBOARDING_STATUS_SUCCESS = 'FETCH_USER_CLIENT_ONBOARDING_STATUS_SUCCESS';
export const FETCH_USER_CLIENT_ONBOARDING_STATUS_FAILURE = 'FETCH_USER_CLIENT_ONBOARDING_STATUS_FAILURE';

export const FETCH_INVOICES_USER_CLIENT_REQUEST = 'FETCH_INVOICES_USER_CLIENT_REQUEST';
export const FETCH_INVOICES_USER_CLIENT_SUCCESS= 'FETCH_INVOICES_USER_CLIENT_SUCCESS';
export const FETCH_INVOICES_USER_CLIENT_FAILURE = 'FETCH_INVOICES_USER_CLIENT_FAILURE';

export const FETCH_VEHICLE_ACTIVE_TRANSFERS_REQUEST = 'FETCH_VEHICLE_ACTIVE_TRANSFERS_REQUEST';
export const FETCH_VEHICLE_ACTIVE_TRANSFERS_SUCCESS= 'FETCH_VEHICLE_ACTIVE_TRANSFERS_SUCCESS';
export const FETCH_VEHICLE_ACTIVE_TRANSFERS_FAILURE = 'FETCH_VEHICLE_ACTIVE_TRANSFERS_FAILURE';

export const FETCH_RECENT_NOTIFICATION_REQUEST = 'FETCH_RECENT_NOTIFICATION_REQUEST';
export const FETCH_RECENT_NOTIFICATION_SUCCESS= 'FETCH_RECENT_NOTIFICATION_SUCCESS';
export const FETCH_RECENT_NOTIFICATION_FAILURE = 'FETCH_RECENT_NOTIFICATION_FAILURE';