import React, {useEffect} from "react";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import TopBar from "../src/Components/Global/TopBar";
import SideBar from "../src/Components/Global/SideBar";
import useResponsiveSidebar from "./utils/isSidebarHidden";
import routes from "./routes";
import "./App.css";

function App() {
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
    const isFullyOnboarded = useSelector((state) => state.auth.isFullyOnboarded);
    const appRoutes = routes(isLoggedIn);
    const location = useLocation();
    const isSidebarHidden = useResponsiveSidebar();


    useEffect(() => {
        function checkWindowSize() {
            if (isLoggedIn && isFullyOnboarded) {
                const sidebar = document.querySelector(".sidebar");
                if (window.innerWidth <= 992) {
                    sidebar.classList.add("hide");
                } else {
                    sidebar.classList.remove("hide");
                }
            }
        }

        // Execute checkWindowSize immediately  const dispatch = useDispatch();

        checkWindowSize();

        window.addEventListener("load", checkWindowSize);
        window.addEventListener("resize", checkWindowSize);

        // Cleanup function to remove the event listeners
        return () => {
            window.removeEventListener("load", checkWindowSize);
            window.removeEventListener("resize", checkWindowSize);
        };
    }, [isLoggedIn, isFullyOnboarded]);


    // Redirect to /signup-information if the user is logged in but not fully onboarded
    if (
        isLoggedIn &&
        !isFullyOnboarded &&
        location.pathname !== "/signup-information" &&
        location.pathname !== "/logout"
    ) {
        return <Navigate to="/signup-information" replace/>;
    }

    return (
        <>
            <TopBar isLoggedIn={isLoggedIn}/>
            {isLoggedIn && isFullyOnboarded && <SideBar className="sidebar"/>}

            <div
                className={
                    isSidebarHidden
                        ? "container-global"
                        : "sidebar-margin container-global"
                }
            >
                <div className={isFullyOnboarded ? "layout-main" : "layout-onboarding"}>
                    <Routes>
                        {appRoutes.flatMap((routeGroup) =>
                            routeGroup.children.map((childRoute, childIndex) => (
                                <Route
                                    key={childIndex}
                                    path={childRoute.path}
                                    element={childRoute.element}
                                />
                            ))
                        )}
                    </Routes>
                </div>
            </div>
        </>
    );
}

export default App;
