import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Alert, AlertTitle, Box, FormHelperText, Grid, IconButton, Modal, TextField, Typography,} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {ArrowBack as ArrowBackIcon} from "@mui/icons-material";
import VehicleDetails from "../../Vehicle/SingleVehiclePage/VehicleDetails";
import {
    fetchVehicleOwnerHistory,
    fetchVehicleSpecificInformation,
    postGranteeTemporaryPermissionToReadHistory,
} from "../../../api";
import PrimaryButton from "../../Global/PrimaryButton";
import CircularProgress from "@mui/material/CircularProgress";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    gap: 2,
};

const ShareHistoryPage = () => {
    const {vehicleUid} = useParams();
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [recipientEmail, setRecipientEmail] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const [readPermissionError, setPostReadPermissionError] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);

    const [historySharingLoading, setHistorySharingLoading] = useState(false);
    const [vehicleSpecificInformation, setVehicleSpecificInformation] =
        useState(null);
    const [vehicleSpecificInformationError, setVehicleSpecificInformationError] =
        useState(null);
    const [
        vehicleSpecificInformationLoading,
        setVehicleSpecificInformationLoading,
    ] = useState(true);

    const [vehicleOwnerHistory, setVehicleOwnerHistory] = useState(null);
    const [vehicleOwnerHistoryError, setVehicleOwnerHistoryError] =
        useState(null);
    const [vehicleOwnerHistoryLoading, setVehicleOwnerHistoryLoading] =
        useState(true);

    useEffect(() => {
        const fetchOwnerHistory = async () => {
            try {
                const vehicleOwnerHistoryResponse = await fetchVehicleOwnerHistory(
                    vehicleUid
                );
                setVehicleOwnerHistory(vehicleOwnerHistoryResponse);
            } catch (error) {
                setVehicleOwnerHistoryError(true);
            } finally {
                setVehicleOwnerHistoryLoading(false);
            }
        };

        fetchOwnerHistory();
    }, [vehicleUid]);

    useEffect(() => {
        const fetchSpecificInformation = async () => {
            try {
                const vehicleSpecificInformationResponse =
                    await fetchVehicleSpecificInformation(vehicleUid);
                setVehicleSpecificInformation(vehicleSpecificInformationResponse);
            } catch (error) {
                setVehicleSpecificInformationError(true);
            } finally {
                setVehicleSpecificInformationLoading(false);
            }
        };

        fetchSpecificInformation();
    }, [vehicleUid]);

    const ERROR_MESSAGES = {
        ownEmailError: t("shareHistoryPage.selfPermissionErrorMessage"),
        genericError: t("shareHistoryPage.genericErrorMessage"),
    };

    const formatEmail = (email) => {
        return email.trim().toLowerCase();
    };
    
    const validateEmail = (email) => {
        return email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
    };

    const handleOpenModal = () => setOpenModal(true);

    const handleCloseModal = async (e) => {
        e.preventDefault();
        try {
            setHistorySharingLoading(true);
            await postGranteeTemporaryPermissionToReadHistory(
                vehicleUid,
                recipientEmail.trim()
            );
            setHistorySharingLoading(false);
            setOpenModal(false);
            navigate(`/vehicles/${vehicleUid}`);
        } catch (error) {
            setOpenModal(false);
            if (error.response && error.response.status === 400) {
                setPostReadPermissionError(ERROR_MESSAGES.ownEmailError);
            } else {
                setPostReadPermissionError(ERROR_MESSAGES.genericError);
            }
            setOpenErrorModal(true);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formattedEmail = formatEmail(recipientEmail);
        if (!validateEmail(formattedEmail)) {
            setEmailError(true);
            return;
        }
        setEmailError(false);
        setRecipientEmail(formattedEmail);
        handleOpenModal();
    };

    return (
        <Box sx={{mt: "2vh"}}>
            <header className="sticky-header">
                <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center">
                        <Link to={`/vehicles/${vehicleUid}`}>
                            <IconButton>
                                <ArrowBackIcon/>
                            </IconButton>
                        </Link>
                        <Typography variant="h4" ml={2}>
                            {t("shareHistoryPage.vehicleHistorySharing")}
                        </Typography>
                    </Box>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        sx={{mt: 2, width: "100%"}}
                    >
                        <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Grid
                                item
                                xs={12}
                                sm={8}
                                md={6}
                                lg={5}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <TextField
                                    fullWidth
                                    label="Recipient's Email"
                                    variant="outlined"
                                    value={recipientEmail}
                                    onChange={(e) => setRecipientEmail(e.target.value)}
                                    error={emailError}
                                    sx={{mr: 2, flex: 1}}
                                />
                                <PrimaryButton
                                    type="submit"
                                    text={t("shareHistoryPage.shareHistory")}
                                />
                            </Grid>
                        </Grid>
                        {emailError && (
                            <Grid item xs={12} sm={8} md={6} lg={5}>
                                <FormHelperText error={true} sx={{textAlign: "center"}}>
                                    {t("shareHistoryPage.invalidEmail")}
                                </FormHelperText>
                            </Grid>
                        )}
                    </Box>
                    <Alert severity="info" sx={{width: "100%", mt: 2}}>
                        {t("shareHistoryPage.bannerDisclaimer")}
                    </Alert>
                </Box>
            </header>
            <VehicleDetails
                vehicleSpecificInformation={vehicleSpecificInformation}
                vehicleSpecificInformationLoading={vehicleSpecificInformationLoading}
                vehicleSpecificInformationError={vehicleSpecificInformationError}
                vehicleOwnerHistory={vehicleOwnerHistory}
                vehicleOwnerHistoryLoading={vehicleOwnerHistoryLoading}
                vehicleOwnerHistoryError={vehicleOwnerHistoryError}
                withActions={false}
            />
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-description" sx={{mt: 2}}>
                        {t("shareHistoryPage.modalDisclaimer")}
                    </Typography>
                    <PrimaryButton
                        text={t("shareHistoryPage.modalAccept")}
                        onClick={handleCloseModal}
                        disabled={historySharingLoading}
                        icon={historySharingLoading ? <CircularProgress size={24}/> : null}
                    />
                </Box>
            </Modal>

            <Modal
                open={openErrorModal}
                onClose={() => setOpenErrorModal(false)}
                aria-labelledby="error-modal-title"
                aria-describedby="error-modal-description"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Box sx={{...style, width: 400, maxWidth: "90vw"}}>
                    <Alert
                        severity="error"
                        icon={<ErrorOutlineIcon fontSize="inherit"/>}
                        sx={{width: "100%", maxWidth: "100%", mb: 2}}
                    >
                        <AlertTitle>{t("shareHistoryPage.errorTitle")}</AlertTitle>
                        <Typography
                            id="error-modal-description"
                            sx={{mt: 2, color: "error.main"}}
                        >
                            {readPermissionError}
                        </Typography>
                    </Alert>
                </Box>
            </Modal>
        </Box>
    );
};

export default ShareHistoryPage;