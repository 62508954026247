import React, { useState } from 'react';
import CustomImageUploadModal from './CustomImageUploadModal';
import CustomImageCheck from './CustomImageCheck';

const CustomImageFlow = ({ vehicleUid, vehicleSpecificInformation, onClose, open }) => {
    const [showCheck, setShowCheck] = useState(false);
    const [croppedImage, setCroppedImage] = useState(null);

    const handleNext = (croppedImage) => {
        setCroppedImage(croppedImage);
        setShowCheck(true); // Move to the check phase after cropping is confirmed
    };

    return (
        <>
            {!showCheck ? (
                <CustomImageUploadModal
                    vehicleUid={vehicleUid}
                    vehicleSpecificInformation={vehicleSpecificInformation}
                    open={open}
                    onClose={onClose}
                    onNext={handleNext}
                />
            ) : (
                <CustomImageCheck
                    vehicleSpecificInformation={vehicleSpecificInformation}
                    vehicleUid={vehicleUid}
                    open={open}
                    onGoBack={() => {
                        setShowCheck(false);
                    }}
                    croppedImage={croppedImage}
                />
            )}
        </>
    );
};

export default CustomImageFlow;
