import React from 'react';
import KeyIcon from '@mui/icons-material/Key';
import {
    Box,
    Card,
    CardContent,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';
import ErrorFallback from '../../Global/ErrorFallback';
import {useTranslation} from 'react-i18next';
import ComponentSkeleton from '../../Global/ComponentSkeleton';
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const VehicleOwnerHistoryCard = ({vehicleOwnerHistory, vehicleOwnerHistoryLoading, vehicleOwnerHistoryError}) => {
    const {t} = useTranslation();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

    if (vehicleOwnerHistoryError) {
        return <ErrorFallback message={t('vehicleOwnerHistoryCard.error')}/>;
    }

    // Ensure vehicleOwnerHistory is not undefined before sorting
    if (vehicleOwnerHistory && vehicleOwnerHistory.length > 0) {
        // Sort vehicle owner history by date of transaction in descending order
        vehicleOwnerHistory.sort((a, b) => {
            const dateA = new Date(a.DateOfTransaction);
            const dateB = new Date(b.DateOfTransaction);
            return dateB - dateA; // Sort by most recent first
        });
    }

    const totalNumberOfPreviousOwners = vehicleOwnerHistory?.reduce((max, item) => {
        const historyData = item;
        const numberOfPreviousKeepers = historyData ? historyData.NumberOfPreviousKeepers : 0;
        return Math.max(max, numberOfPreviousKeepers);
    }, 0);

    if (totalNumberOfPreviousOwners === 0) {
        return (
            <>
                <Grid container justifyContent="center" alignItems="stretch" >
                    <Grid item xs={12}>
                        <Card sx={{
                            m: 2,
                            minHeight: isSmallScreen? 'auto' : '50vh',
                            boxShadow: 3,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <CardContent sx={{textAlign: 'center'}}>
                                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <KeyIcon sx={{fontSize: 60, color: 'text.secondary'}}/>
                                    <Typography variant="h6" gutterBottom sx={{mt: 2}}>
                                        {t('vehicleOwnerHistoryCard.noPreviousOwners')}
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.secondary">
                                        {t('vehicleOwnerHistoryCard.noPreviousOwnersSubtext')}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </>
        );
    }

    return (
        <>
            <Grid container justifyContent="center" alignItems="stretch" sx={{minHeight: '30vh'}}>
                {vehicleOwnerHistoryLoading ? (
                    <ComponentSkeleton/>
                ) : (
                    <Grid item xs={12}>
                        <Card sx={{
                            minHeight: isSmallScreen? 'auto' : '50vh',
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                            m: 2,
                            boxShadow: 3
                        }}>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    {t('vehicleOwnerHistoryCard.ownerHistory')}
                                </Typography>
                                <Typography gutterBottom>
                                    {`${t('vehicleOwnerHistoryCard.totalNumberOfPreviousOwners')}: ${totalNumberOfPreviousOwners}`}
                                </Typography>
                                <List sx={{
                                    overflowY: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}>
                                    {vehicleOwnerHistory?.map((historyData, index) => {
                                        const {DateOfTransaction, NumberOfPreviousKeepers} = historyData;
                                        const formattedDateOfTransaction = DateOfTransaction
                                            ? new Date(DateOfTransaction).toLocaleDateString()
                                            : 'N/A';

                                        return (
                                            <React.Fragment key={index}>
                                                {index > 0 && <Divider variant="inset" component="li"/>}
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <KeyIcon/>
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={`Transaction Date: ${formattedDateOfTransaction}`}
                                                        secondary={`Previous Keepers: ${NumberOfPreviousKeepers}`}
                                                    />
                                                </ListItem>
                                            </React.Fragment>
                                        );
                                    })}
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default VehicleOwnerHistoryCard;
