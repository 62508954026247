import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import SecondaryButton from "../../../Global/SecondaryButton";
import PrimaryButton from "../../../Global/PrimaryButton";

const ActionButtons = ({ onBack, onConfirm }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const containerStyle = {
    display: "flex",
    justifyContent: isSmallScreen ? "center" : "flex-end",
    marginTop: isSmallScreen ? "16px" : 0
  };

  const actionButtonStyle = {
    margin: isSmallScreen ? "8px" : "0 0 0 16px"
  };

  return (
    <Grid item xs={12} style={containerStyle}>
      <SecondaryButton onClick={onBack} text={t("addVehicleCard.back")} sx={actionButtonStyle} />
      <PrimaryButton onClick={onConfirm} text={t("addVehicleCard.confirm")} sx={actionButtonStyle} />
    </Grid>
  );
};

export default ActionButtons;
