import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Card, CardActionArea, CardContent, CardMedia, Chip, Skeleton, Typography} from '@mui/material';
import RegistrationPlate from '../../../Global/RegistrationPlate';
import {fetchVehicleImage} from '../../../../api';
import GlobalDetails from '../../Global/GlobalDetails';

const DetailedVehicleCard = ({vehicle}) => {
    const [imageData, setImageData] = useState("");
    const [imageLoaded, setImageLoaded] = useState(false);
    const [imageLoadError, setImageLoadError] = useState(false);
    const navigate = useNavigate();
    const {
        id,
        identification,
        make,
        model,
        trim,
        type,
        year,
        color,
        fuelType,
        hasActiveTransfer
    } = vehicle;

    useEffect(() => {
        if (!identification) return;
        (async () => {
            try {
                const imageDataBase64 = await fetchVehicleImage(identification);
                setImageData(imageDataBase64);
                setImageLoaded(true);
            } catch (error) {
                setImageLoadError(true);
            } finally {
                setImageLoaded(true);
            }
        })();
    }, [identification]);

    const handleCardClick = () => {
        navigate(`/vehicles/${id}`);
    };

    return (
        <Card sx={{display: 'flex', bgcolor: 'background.paper', boxShadow: 1, width: '100%'}}>
            <CardActionArea onClick={handleCardClick} sx={{display: 'flex', width: '100%'}}>

                {!imageLoaded ? (
                        <Skeleton variant="rectangular" width="30vw" height='20vh'/>
                    ) :
                    <CardMedia
                        component="img"
                        src={`data:image/jpeg;base64,${imageData}`}
                        alt={`${make} ${model}`}
                        onError={(e) => (e.target.src = "/pitstop.png")}
                        sx={{objectFit: "contain", width: "30vw", maxHeight: '20vh'}}
                    />
                }
                <CardContent
                    sx={{flex: '1 0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <Typography variant="h5" component="div" gutterBottom>
                        {`${make} ${model}`} {trim && ` - ${trim}`}
                    </Typography>

                    <RegistrationPlate identification={identification}/>
                    <GlobalDetails type={type} yearOfManufacture={year} fuelType={fuelType} color={color}/>

                    {hasActiveTransfer && (
                        <Chip
                            label="Active Transfer Request"
                            size="medium"
                            sx={{
                                mt: 2,
                                fontSize: '1rem',
                                bgcolor: '#FFC107',
                                color: 'black',
                                '& .MuiChip-label': {fontWeight: 'bold'},
                            }}
                        />
                    )}
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default DetailedVehicleCard;
