import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {deleteVehicleImage, fetchVehicleImage, fetchVehicleUserInformation} from "../../../../api";
import {useTranslation} from "react-i18next";
import {Box, Card, CardContent, CardMedia, IconButton, Tooltip} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import RegistrationPlate from "../../../Global/RegistrationPlate";
import GlobalDetails from "../../Global/GlobalDetails";
import "../VehicleDetails.css";
import PrimaryButton from "../../../Global/PrimaryButton";
import SecondaryButton from "../../../Global/SecondaryButton";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MotInfoModal from "./MotInfoModal";
import VehicleActionsModal from "./VehicleActionsModal";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CustomImageFlow from "./CustomImage/CustomImageFlow";

const VehicleInfoCard = (
    {
        withActions,
        vehicleSpecificInformation,
        vehicleSpecificInformationLoading,
        vehicleSpecificInformationError,
        minHeight
    }) => {
    const {t} = useTranslation();
    const {vehicleUid} = useParams();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

    const [vehicleActionModalOpen, setVehicleActionModalOpen] = useState(false);
    const [hasCustomImage, setHasCustomImage] = useState(false);
    const [vehicleUserInformationError, setVehicleUserInformationError] = useState(false);

    const [motModalOpen, setMotModalOpen] = useState(false);

    const [customImageFlowOpen, setCustomImageFlowOpen] = useState(false);
    const [imageLoaded, setImageLoaded] = useState(false);

    const [imageData, setImageData] = useState("");

    useEffect(() => {
        (async () => {
            try {
                const response = await fetchVehicleUserInformation(vehicleUid);
                setHasCustomImage(response.hasCustomImage);
            } catch (error) {
                console.error("Failed to fetch vehicle user information:", error);
                setVehicleUserInformationError(true);
            }
        })();
    }, [vehicleUid]);

    useEffect(() => {
        if (!vehicleSpecificInformation.vrm) return;
        (async () => {
            try {
                const imageDataBase64 = await fetchVehicleImage(vehicleSpecificInformation.vrm);
                setImageData(imageDataBase64);
                setImageLoaded(true);
            } catch (error) {
                setImageLoaded(true);
            }
        })();
    }, [vehicleSpecificInformation.vrm]);

    const handleRemoveImage = async () => {
        try {
            await deleteVehicleImage(vehicleUid);
            setHasCustomImage(false);
            window.location.reload();
        } catch (error) {
            console.error("Error removing image:", error);
        }
    };

    return (
        <>
            <Card
                sx={{
                    height: '50vh',
                    width: isSmallScreen ? '100%' : '45vw',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    m: 2,
                    boxShadow: 3
                }}>
                <CardMedia component="img" src={`data:image/jpeg;base64,${imageData}`} alt="Vehicle"
                           onError={(e) => (e.target.src = "/pitstop.png")} sx={{
                    objectFit: 'contain',
                    width: '80vw',
                    height: '25vh',
                    maxWidth: isSmallScreen ? '90vw' : '30vw',
                    maxHeight: isSmallScreen ? '25vh' : '30vh',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    display: 'block'
                }}/>
                {hasCustomImage ? (
                    <Tooltip title={t("vehicleInfoCard.removeImage")} enterDelay={500} leaveDelay={200}>
                        <IconButton
                            sx={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                                color: 'error.main',
                                backgroundColor: 'white',
                                '&:hover': {backgroundColor: 'grey.100'},
                            }}
                            onClick={handleRemoveImage}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </Tooltip>
                ) : withActions ? (
                    <Tooltip title={t("vehicleInfoCard.uploadImage")} enterDelay={500} leaveDelay={200}>
                        <IconButton
                            sx={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                                color: 'primary.main',
                                backgroundColor: 'white',
                                '&:hover': {backgroundColor: 'grey.100'},
                            }}
                            component="label"
                            onClick={() => setCustomImageFlowOpen(true)}
                        >
                            <CloudUploadIcon/>
                        </IconButton>
                    </Tooltip>
                ) : null}

                <CardContent sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Box sx={{width: '100%', textAlign: 'center'}}>
                        <RegistrationPlate identification={vehicleSpecificInformation.vrm}/>
                    </Box>
                    {withActions ? (
                        <Box sx={{display: 'flex', justifyContent: 'center', gap: 2, mt: 2}}>
                            <PrimaryButton
                                text={t("vehicleInfoCard.actions")}
                                onClick={() => setVehicleActionModalOpen(true)}
                            />
                        </Box>
                    ) : (
                        // Spacer with the same dimensions as the buttons
                        <Box sx={{height: '2vh', display: 'flex'}}>

                        </Box>
                    )}

                    <GlobalDetails type={vehicleSpecificInformation.vehicleClass}
                                   yearOfManufacture={vehicleSpecificInformation.yearOfManufacture}
                                   color={vehicleSpecificInformation.colour}
                                   fuelType={vehicleSpecificInformation.fuelType}/>
                </CardContent>
            </Card>

            <CustomImageFlow
                vehicleUid={vehicleUid}
                vehicleSpecificInformation={vehicleSpecificInformation}
                open={customImageFlowOpen}
                onClose={() => setCustomImageFlowOpen(false)}
            />
            <VehicleActionsModal
                vehicleUid={vehicleUid}
                identification={vehicleSpecificInformation.vrm}
                onClose={() => setVehicleActionModalOpen(false)}
                open={vehicleActionModalOpen}
            />
        </>
    )
        ;
};

export default VehicleInfoCard;
