import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography,} from "@mui/material";
import {deleteAccount} from "../../api";
import {useDispatch} from "react-redux";
import {authLogout} from "../../redux/authActions";
import ErrorFallback from "../Global/ErrorFallback";

const DeleteAccountConfirmationModal = ({open, onClose}) => {
    const {t} = useTranslation();
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const dispatch = useDispatch();

    const handleInputChange = (e) => {
        // Remove newline characters, spaces, and tabs from the input value
        const sanitizedValue = e.target.value.replace(/[\n\s\t]/g, '');
        setPassword(sanitizedValue);
    };

    const handleDelete = async (event) => {
        event.preventDefault();

        try {
            await deleteAccount(password);
            dispatch(authLogout());
            window.location.href = '/logout';
        } catch (error) {
            setError(error);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="delete-account-dialog-title"
        >
            {!error && (
                <DialogTitle id="delete-account-dialog-title">
                    {t("deleteAccountConfirmationModal.title")}
                </DialogTitle>
            )}
            <DialogContent>
                {error ? (
                    <ErrorFallback message={t("userInfo.errorFallback")}/>
                ) : (
                    <>
                        <Typography>
                            {t("deleteAccountConfirmationModal.disclaimer")}
                        </Typography>
                        <TextField
                            autoFocus
                            margin="dense"
                            label={t("deleteAccountConfirmationModal.password")}
                            type="password"
                            fullWidth
                            value={password}
                            onChange={handleInputChange} // Use the custom event handler
                        />
                    </>
                )}
            </DialogContent>
            {!error && (
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        {t("deleteAccountConfirmationModal.cancel")}
                    </Button>
                    <Button onClick={handleDelete} color="error">
                        {t("deleteAccountConfirmationModal.deleteAccount")}
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
};

export default DeleteAccountConfirmationModal;
