import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Divider,
  Chip,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from '@mui/icons-material/Cancel';
import WarningIcon from "@mui/icons-material/Warning";
import {useTranslation} from "react-i18next";

const MotRecordCard = ({ motRecord, mileageAnomaly }) => {
  const {
    TestNumber,
    TestDate,
    ExpiryDate,
    TestResult,
    OdometerReading,
    OdometerUnit,
    AdvisoryNoticeList,
  } = motRecord;
  const {t} = useTranslation();

  return (
    <Card variant="outlined" sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h6" component="h2" gutterBottom>
          {TestResult === "Pass" ? (
            <CheckCircleIcon color="success" />
          ) : (
            <CancelIcon color="error" />
          )}{" "}{t("motRecordCard.title")}{" "}
          {mileageAnomaly ? (
            <Chip
              icon={<WarningIcon />}
              label={t("motRecordCard.mileageAnomalyDetected")}
              color="warning"
              size="small"
              style={{ marginLeft: "10px" }}
            />
          ) : (
            <Chip
              icon={<CheckCircleIcon />}
              label={t("motRecordCard.noMileageAnomalyDetected")}
              color="success"
              size="small"
              style={{ marginLeft: "10px" }}
            />
          )}
        </Typography>
        <Divider sx={{ my: 1 }} />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="body2" color="text.secondary">
              {t("motRecordCard.testNumber")}: {TestNumber}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="text.secondary">
              {t("motRecordCard.testDate")}: {TestDate}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="text.secondary">
              {t("motRecordCard.expiryDate")}: {ExpiryDate}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="text.secondary">
              {t("motRecordCard.odometerReading")}: {OdometerReading} {OdometerUnit}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {AdvisoryNoticeList &&
              AdvisoryNoticeList.map((notice, index) => (
                <Typography
                  key={index}
                  variant="body2"
                  sx={{
                    fontFamily: "'Courier Prime', monospace",
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                    padding: "8px",
                    borderRadius: "4px",
                    margin: "4px 0",
                  }}
                >
                  {notice}
                </Typography>
              ))}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default MotRecordCard;
