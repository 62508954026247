import React, { useState, useEffect } from 'react';
import './Step2EmailConfirmation.css';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../../../../Global/PrimaryButton';
import {postStep1ResendEmail, postStep1ResendEmailLoggedIn} from '../../../../../api';
import Snackbar from '@mui/material/Snackbar';
import {useSelector} from "react-redux";

const Step2EmailConfirmation = ({ emailAddress }) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [showResendHint, setShowResendHint] = useState(false);
  const [resendClicked, setResendClicked] = useState(false);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  useEffect(() => {
    const timer = setTimeout(() => setShowResendHint(true), 10000); // 10 seconds
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (resendClicked) {
      const timer = setTimeout(() => setResendClicked(false), 10000); // 10 seconds
      return () => clearTimeout(timer);
    }
  }, [resendClicked]);

  const handleResendEmail = async () => {
    try {

      if (!isLoggedIn && emailAddress) {
        setResendClicked(true);
        await postStep1ResendEmail({email: emailAddress});
      }

      if (isLoggedIn && !emailAddress) {
        setResendClicked(true);
        await postStep1ResendEmailLoggedIn();
      }
    } catch (error) {
      setErrorMessage('Failed to resend email. Please try again later.');
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
      <div className="step2-container">
        <div className="signup-form bg-light p-4 rounded shadow-sm">
          <h2 className="mb-4 text-center font-weight-bold">{t('confirmEmail.header')}</h2>
          {emailAddress ? (
              <>
                <p className="confirmation-email">{t('confirmEmail.instruction')}</p>
                <div className="mb-3">
                  <input
                      type="email"
                      id="email"
                      value={emailAddress}
                      disabled
                      className="form-control"
                  />
                </div>

              </>
          ) : (
              <p className="confirmation-email">{t('confirmEmail.instruction')}</p>
          )}
          {showResendHint && !resendClicked && (
              <p className="resend-hint">
                {t('confirmEmail.notReceived')}{' '}
                <PrimaryButton text={t("confirmEmail.resend")} onClick={handleResendEmail} />
              </p>
          )}

          <Snackbar
              open={snackbarOpen}
              autoHideDuration={3000}
              onClose={handleCloseSnackbar}
              message={errorMessage}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              ContentProps={{
                sx: {
                  backgroundColor: "red",
                  color: "#fff"
                }
              }}
          />
        </div>
      </div>
  );
}

  export default Step2EmailConfirmation;
