import {
    FETCH_INVOICES_USER_CLIENT_REQUEST,
    FETCH_INVOICES_USER_CLIENT_SUCCESS,
    FETCH_INVOICES_USER_CLIENT_FAILURE,
} from '../actionTypes';

const initialState = {
    invoicesLoading: false,
    invoices: [],
    invoicesError: '',
};

const invoiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_INVOICES_USER_CLIENT_REQUEST:
            return {
                ...state,
                invoicesLoading: true,
            };
        case FETCH_INVOICES_USER_CLIENT_SUCCESS:
            return {
                ...state,
                invoicesLoading: false,
                invoices: action.payload,
                invoicesError: '',
            };
        case FETCH_INVOICES_USER_CLIENT_FAILURE:
            return {
                ...state,
                invoicesLoading: false,
                invoices: [],
                invoicesError: action.payload,
            };
        default:
            return state;
    }
};

export default invoiceReducer;
