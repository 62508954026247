import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {format} from "date-fns";
import {
    Box,
    Container,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs
} from "@mui/material";
import NoInvoiceMessage from "./NoInvoiceMessage";
import DisplayS3Object from "../../Global/S3Object/DisplayS3Object";
import {useDispatch, useSelector} from "react-redux";
import {CloudUpload as CloudUploadIcon} from "@mui/icons-material";
import {fetchInvoicesForClient} from "../../../redux/Invoice/actions";
import ManualUploadModal from "../ManualUpload/ManualUploadModal";
import LoadingSkeleton from "../../Global/LoadingSkeleton";
import PrimaryButton from "../../Global/PrimaryButton";
import ErrorFallback from "../../Global/ErrorFallback";
import {fetchVehiclesforUser} from "../../../redux/Vehicle/actions";

function InvoiceManagement() {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {invoicesLoading, invoices, invoicesError} = useSelector(state => state.invoices);
    const {userVehicles, userVehiclesLoading, userVehiclesError} = useSelector(state => state.vehicles);

    useEffect(() => {
        if (!invoices.length) {
            dispatch(fetchInvoicesForClient());
        }
        if (!userVehicles.length) {
            dispatch(fetchVehiclesforUser());
        }
    }, [dispatch, invoices.length, userVehicles.length]);

    const vehicles = [...new Set(invoices.map(invoice => invoice.vehicleIdentification))];
    const [selectedTab, setSelectedTab] = useState(0);
    const [openModal, setOpenModal] = useState(false);

    const handleTabChange = (event, newValue) => setSelectedTab(newValue);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    return (
        <Box sx={{mt: 2}}>
            {invoicesError || userVehiclesError ? (
                <Box display="flex" alignItems="center" justifyContent="center" height="70vh">
                    <ErrorFallback message={t("userInfo.errorFallback")}/>
                </Box>
            ) : (
                <>
                    <header>
                        <Box display="flex" alignItems="center" justifyContent="space-between" mt={3} ml={6}>
                            <PrimaryButton
                                text={t("invoiceManagement.upload")}
                                onClick={handleOpenModal}
                                icon={<CloudUploadIcon/>}
                            />
                        </Box>
                    </header>

                    <main>
                        <Container sx={{mt: 1}}>
                            <Tabs value={selectedTab} onChange={handleTabChange} variant="scrollable"
                                  scrollButtons="auto">
                                {vehicles.map((vehicle, index) => (
                                    <Tab key={vehicle} label={vehicle}/>
                                ))}
                            </Tabs>
                            {invoicesLoading || userVehiclesLoading ? (
                                <LoadingSkeleton rows={3}/>
                            ) : invoices.length ? (
                                vehicles.map((vehicle, index) => (
                                    <TableContainer key={vehicle} hidden={selectedTab !== index}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>{t("invoiceManagement.uploadDate")}</TableCell>
                                                    <TableCell>{t("invoiceManagement.invoiceDate")}</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {invoices.filter(invoice => invoice.vehicleIdentification === vehicle).map((invoice) => (
                                                    <TableRow key={invoice.id}>
                                                        <TableCell>{format(new Date(invoice.createdAt), "MMMM dd, yyyy")}</TableCell>
                                                        <TableCell>{format(new Date(invoice.invoiceDate), "MMMM dd, yyyy")}</TableCell>
                                                        <TableCell>
                                                            <DisplayS3Object invoiceId={invoice.id} fulltext={true}/>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                ))
                            ) : (
                                <NoInvoiceMessage/>
                            )}
                        </Container>
                    </main>
                    <ManualUploadModal open={openModal} onClose={handleCloseModal}/>
                </>
            )}

        </Box>
    );
}

export default InvoiceManagement;
