import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchVehiclesforUser } from '../../../redux/Vehicle/actions';
import StepperComponent from './Stepper/StepperComponent';
import UploadProofOfOwnership from './UploadProofOfOwnership/UploadProofOfOwnership';
import AddVehicleForm from './AddVehicleForm';
import ConfirmVehicle from './ConfirmVehicle/ConfirmVehiclePage';

function AddVehicle() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [step, setStep] = useState(1);
    const [vehicleUid, setVehicleId] = useState(false);
    const [officialVehicleData, setOfficialVehicleData] = useState(false);

    const handleBack = (e) => {
        e.preventDefault();
        setStep(1);
    };

    useEffect(() => {
        if (step == 4) {
            dispatch(fetchVehiclesforUser());
            navigate('/');
        }
    }, [step, dispatch, navigate]);

    
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '3vh', marginBottom: '4vh'}}>
            <StepperComponent activeStep={step - 1} />

            {step === 1 && (
                <AddVehicleForm
                    setStep={setStep}
                    setVehicleData={setOfficialVehicleData} 
                />
            )}

            {step === 2 && officialVehicleData && (
                <ConfirmVehicle
                    setStep={setStep}
                    vehicle={officialVehicleData}
                    onBack={handleBack}
                    setNewVehicleId={setVehicleId}
                />
            )}

            {step === 3 && (
                <UploadProofOfOwnership
                    setStep={setStep}
                    onBack={handleBack}
                    vehicleUid={vehicleUid}
                />
            )}
        </div>
    );
}

export default AddVehicle;
