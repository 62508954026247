import React from 'react';
import {
    Box,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Typography
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {useTranslation} from "react-i18next";

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px 0',
    },
    dropzone: {
        border: '2px dashed #cccccc',
        borderRadius: '8px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
        margin: '20px 0',
        width: '50%',
    },
    fileListItem: {
        backgroundColor: '#f5f5f5',
        marginBottom: '10px',
        borderRadius: '4px',
    },
    fileList: {
        width: '70%',
    },
    cloudUploadIcon: {
        fontSize: '4vh',
        color: '#666666',
    },
};
//todo delete and replace with custom dropzone
const ProofDropzone = ({getRootProps, getInputProps, isDragActive, selectedFiles, removeFile}) => {
    const {t} = useTranslation();


    return (
        <Box style={styles.container}>
            <Paper elevation={3} {...getRootProps()} style={styles.dropzone}>
                <input {...getInputProps()} />
                {isDragActive ? (
                    <Typography variant="h6">
                        {t("customDropzone.instructionsDrop")}
                    </Typography>
                ) : (
                    <Box textAlign="center">
                        <CloudUploadIcon style={styles.cloudUploadIcon}/>
                        <Typography variant="h6">
                            {t("customDropzone.instructionsFull")}
                        </Typography>
                    </Box>
                )}
            </Paper>
            {selectedFiles.length > 0 && (
                <List style={styles.fileList}>
                    {selectedFiles.map((file, index) => (
                        <ListItem key={`${file.name}-${index}`} style={styles.fileListItem}>
                            <ListItemIcon>
                                <InsertDriveFileIcon/>
                            </ListItemIcon>
                            <ListItemText primary={file.name}/>
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="delete" onClick={() => removeFile(file.name)}>
                                    <DeleteIcon/>
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            )}
        </Box>
    )
};

export default ProofDropzone;
