import React from 'react';
import { useTranslation } from 'react-i18next';
import { getCode } from 'country-list';
import ReactCountryFlag from 'react-country-flag';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const VehicleInfo = ({ vehicle }) => {
    const { t } = useTranslation();
    const formattedFuelType = vehicle.fuelType ? vehicle.fuelType.charAt(0).toUpperCase() + vehicle.fuelType.slice(1).toLowerCase() : '';
    const date = vehicle.firstRegistrationDate ? new Date(vehicle.firstRegistrationDate) : null;
    const formattedDate = date ? date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : '';
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));


    const tableStyle = {
        width: isSmallScreen ? '100%' : '60vw',
        maxWidth: '70%'
      };

    return (
            <table style={tableStyle}>
                <tbody>
                    {vehicle.vehicleIdentification && (
                        <tr>
                            <td>{t('addVehicleCard.vehicleIdentification')}</td>
                            <td>{vehicle.vehicleIdentification}</td>
                        </tr>
                    )}
                    {vehicle.type && (
                        <tr>
                            <td>{t('addVehicleCard.type')}</td>
                            <td>{vehicle.type}</td>
                        </tr>
                    )}
                    {vehicle.color && (
                        <tr>
                            <td>{t('addVehicleCard.color')}</td>
                            <td>
                                <div style={{
                                    width: '20px',
                                    height: '20px',
                                    backgroundColor: vehicle.color.toLowerCase(),
                                    border: '1px solid #ccc'
                                }}></div>
                            </td>
                        </tr>
                    )}
                    {vehicle.numberOfDoors && (
                        <tr>
                            <td>{t('addVehicleCard.doors')}</td>
                            <td>{vehicle.numberOfDoors}</td>
                        </tr>
                    )}
                    {formattedFuelType && (
                        <tr>
                            <td>{t('addVehicleCard.fuelType')}</td>
                            <td>{formattedFuelType}</td>
                        </tr>
                    )}
                    {vehicle.numberOfGears && (
                        <tr>
                            <td>{t('addVehicleCard.gears')}</td>
                            <td>{vehicle.numberOfGears}</td>
                        </tr>
                    )}
                    {vehicle.countryOfOrigin && (
                        <tr>
                            <td>{t('addVehicleCard.origin')}</td>
                            <td>
                                {vehicle.countryOfOrigin}
                                <ReactCountryFlag countryCode={getCode(vehicle.countryOfOrigin)} svg style={{ marginLeft: '10px' }} />
                            </td>
                        </tr>
                    )}
                    {formattedDate && (
                        <tr>
                            <td>{t('addVehicleCard.firstRegistration')}</td>
                            <td>{formattedDate}</td>
                        </tr>
                    )}
                </tbody>
            </table>
    );
};

export default VehicleInfo;
