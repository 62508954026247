import React from 'react';
import { ListItem, Divider, Button, Grid, Typography, Box, Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import RegistrationPlate from '../../../Global/RegistrationPlate';

const RecapVehicleCard = ({ vehicle, index, totalVehicles }) => {
  const { t } = useTranslation();
  const { id, identification, make, model, trim, type, year, color, fuelType, hasActiveTransfer } = vehicle;

  return (
    <React.Fragment>
      <Button
        style={{
          width: '100%',
          justifyContent: 'flex-start',
          textTransform: 'none',
          padding: 0,
        }}
        component="a"
        href={`/vehicles/${id}`}
        sx={{
          color: 'var(--color-main)',
          '&:hover': {
            color: 'var(--color-secondary)', 
            '.MuiTypography-root, .MuiChip-root': {
              color: 'inherit',
            }
          },
        }}
      >
        <ListItem alignItems="flex-start" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <RegistrationPlate identification={identification} />
            </Grid>
            <Grid item xs>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <Typography variant="h6" component="div">
                  {`${year} ${make} ${model}`}
                </Typography>
                {hasActiveTransfer && (
                  <Chip label={t('vehicle.activeTransfer')} color="warning" size="small" />
                )}
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                <Box
                  sx={{
                    width: 20,
                    height: 20,
                    backgroundColor: vehicle.color.toLowerCase(),
                    border: '1px solid #ccc',
                    marginRight: 1,
                    flexShrink: 0,
                  }}
                />
                {trim && <Typography variant="body2">{trim}</Typography>}
              </Box>
            </Grid>
          </Grid>
        </ListItem>
      </Button>
      {index !== totalVehicles - 1 && <Divider />}
    </React.Fragment>
  );
};

export default RecapVehicleCard;
