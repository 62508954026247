import React from 'react';
import { Card, Box, Skeleton, CardContent, CardHeader, Divider } from '@mui/material';

const TransferCardSkeleton = ({ count }) => {
    const skeletons = Array.from({ length: count }, (_, index) => (
        <Card key={index} sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, mb: 2, overflow: 'hidden', boxShadow: 3 }}>
            {/* Adjust Box to center the Skeleton vertically */}
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: 'grey.300',
                borderRadius: '50%',
                minWidth: 100,
                minHeight: 100,
                maxWidth: 100,
                maxHeight: 100,
                m: 2,
                alignSelf: 'center'  // This ensures the skeleton is centered vertically
            }}>
                <Skeleton variant="circular" width={100} height={100} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, paddingX: 2 }}>
                <CardHeader
                    title={<Skeleton variant="text" width="40%" height={28} />}
                    subheader={<Skeleton variant="text" width="30%" height={20} />}
                />
                <Divider variant="middle" />
                <CardContent sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start' }}>
                    <Skeleton variant="rectangular" width="30%" height={100} sx={{ borderRadius: 1, mr: 2 }} />
                    <Divider orientation="vertical" flexItem />
                </CardContent>
            </Box>
        </Card>
    ));

    return <Box>{skeletons}</Box>;
};

export default TransferCardSkeleton;
