import React from "react";
import { useSelector } from "react-redux";
import { Box, Grid, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import DetailedVehicleCard from "./DetailedVehicleCard";

const SmallVehicleCountLayout = () => {
  const userVehicles = useSelector((state) => state.vehicles.userVehicles);
  const navigate = useNavigate();

  const handleAddVehicleClick = () => {
    navigate("/vehicles/add");
  };

  return (
    <Box sx={{ mt: "4vh", maxHeight: "77vh", overflowY: "auto" }}>
      <Grid container spacing={4}>
        {userVehicles.map((vehicle) => (
          <Grid item xs={12} sm={12} md={12} key={vehicle.id}>
            <DetailedVehicleCard vehicle={vehicle} />
          </Grid>
        ))}
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <IconButton
            color="primary"
            onClick={handleAddVehicleClick}
            size="large"
            sx={{
              color: "white",
              fontSize: "5rem",
              backgroundColor: "var(--color-main)",
              "&:hover": {
                backgroundColor: "var(--color-secondary)",
              },
            }}
          >
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SmallVehicleCountLayout;
