import {
    FETCH_USER_CLIENT_ONBOARDING_STATUS_REQUEST,
    FETCH_USER_CLIENT_ONBOARDING_STATUS_SUCCESS,
    FETCH_USER_CLIENT_ONBOARDING_STATUS_FAILURE,
} from '../actionTypes';

const initialState = {
    onboardingStatusLoading: false,
    onboardingStatus: false,
    onboardingStatusError: '',
};

const onboardingStatusReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USER_CLIENT_ONBOARDING_STATUS_REQUEST:
            return {
                ...state,
                onboardingStatusLoading: true,
            };
        case FETCH_USER_CLIENT_ONBOARDING_STATUS_SUCCESS:
            return {
                ...state,
                onboardingStatusLoading: false,
                onboardingStatusUpdated: true,
                onboardingStatusError: '',
                onboardingStatus: action.payload,
            };
        case FETCH_USER_CLIENT_ONBOARDING_STATUS_FAILURE:
            return {
                ...state,
                onboardingStatusLoading: false,
                onboardingStatusUpdated: false,
                onboardingStatusError: action.payload,
            };
        default:
            return state;
    }
};

export default onboardingStatusReducer;
