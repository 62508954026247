import React from 'react';
import { Box, Skeleton } from '@mui/material';

const LoadingSkeleton = ({ rows = 5, includeImage = false, imageHeight = 200 }) => {
  return (
    <Box>
      {includeImage && <Skeleton variant="rectangular" height={imageHeight} style={{ marginBottom: '16px' }} />}
      {[...Array(rows)].map((_, index) => (
        <React.Fragment key={index}>
          <Skeleton variant="text" width="100%" height={60} style={{ marginBottom: '8px' }} />
          <Skeleton variant="rectangular" width="100%" height={60} style={{ marginBottom: '16px' }} />
        </React.Fragment>
      ))}
    </Box>
  );
};

export default LoadingSkeleton;
