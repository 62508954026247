import {Box, List, ListItem, ListItemIcon, ListItemText, Typography} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import React from 'react';
import {useTranslation} from "react-i18next";

const AcceptableProofofOwnershipList = () => {
    const {t} = useTranslation();

    return (
        <Box flex={1} padding={{xs: 2, md: 0}} textAlign={{xs: 'center', md: 'left'}}>
            <Typography variant="subtitle1" gutterBottom style={{fontWeight: 'bold'}}>
                {t("proofOfOwnershipList.title")}
            </Typography>
            <List dense>
                <ListItem disableGutters>
                    <ListItemIcon>
                        <CheckCircleOutlineIcon fontSize="small" color="primary"/>
                    </ListItemIcon>
                    <ListItemText primary={t("proofOfOwnershipList.vehicleTitle")}/>
                </ListItem>
                <ListItem disableGutters>
                    <ListItemIcon>
                        <CheckCircleOutlineIcon fontSize="small" color="primary"/>
                    </ListItemIcon>
                    <ListItemText primary={t("proofOfOwnershipList.billOfSale")}/>
                </ListItem>
                <ListItem disableGutters>
                    <ListItemIcon>
                        <CheckCircleOutlineIcon fontSize="small" color="primary"/>
                    </ListItemIcon>
                    <ListItemText primary={t("proofOfOwnershipList.registrationCertificate")}/>
                </ListItem>
                <ListItem disableGutters>
                    <ListItemIcon>
                        <CheckCircleOutlineIcon fontSize="small" color="primary"/>
                    </ListItemIcon>
                    <ListItemText primary={t("proofOfOwnershipList.insurance")}/>
                </ListItem>
            </List>
        </Box>
    )
};

export default AcceptableProofofOwnershipList;
