import React, { useState, useEffect } from "react";
import { CircularProgress, Snackbar, SnackbarContent } from "@mui/material";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import ErrorFallback from "../../../../Global/ErrorFallback";
import "./Step3UserInfo.css";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../../../../Global/PrimaryButton";
import { postStep3PersonalInformation } from "../../../../../api";

const Step3UserInfo = ({ handleUserInfoSubmit }) => {
  const { t } = useTranslation();

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dateOfBirth, setDob] = useState("");
  const [dobError, setDobError] = useState("");

  const [postStep3Loading, setPostStep3Loading] = useState(false);
  const [postStep3Success, setPostStep3Success] = useState(false);
  const [postStep3Error, setPostStep3Error] = useState(false);
  const [title, setTitle] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");

  useEffect(() => {
    if (
      postStep3Error &&
      postStep3Error.response &&
      postStep3Error.response.status === 409
    ) {
      setPhoneNumberError("This phone number is already in use.");
    } else {
      setPhoneNumberError("");
    }
  }, [postStep3Error]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsSnackbarOpen(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  const validateAge = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age >= 16;
  };

  const submitInfo = async (e) => {
    e.preventDefault();
    if (!validateAge(dateOfBirth)) {
      setDobError("You must be at least 13 years old.");
      return;
    }
    setDobError(""); // Clear any existing error message
    setPostStep3Loading(true);

    try {
      const userData = {
        firstName:
          firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase(),
        lastName:
          lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase(),
        phoneNumber,
        title,
        dateOfBirth,
      };
      await postStep3PersonalInformation(userData);

      setPostStep3Success(true);
      handleUserInfoSubmit();
    } catch (error) {
      setPostStep3Error(error);
    } finally {
      setPostStep3Loading(false);
    }
  };

  return postStep3Error &&
    postStep3Error.response &&
    postStep3Error.response.status !== 409 ? (
    <ErrorFallback message={t("userInfo.errorFallback")} />
  ) : (
    <div>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <SnackbarContent
          message={t("userInfo.emailConfirmed")}
          style={{ marginTop: "15vh", backgroundColor: "var(--color-main)" }}
        />
      </Snackbar>

      <form className="signup-form bg-light p-4 rounded shadow-sm">
        <h2 className="mb-4 text-center font-weight-bold">
          {t("userInfo.header")}
        </h2>

        <div className="mb-3 row">
          <div className="col-md-4">
            <label htmlFor="title" className="form-label font-weight-bold">
              {t("userInfo.title")}
            </label>
            <select
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="form-control"
              required
            >
              <option value="">{t("userInfo.titleSelection")}</option>
              <option value="mr">{t("userInfo.mr")}</option>
              <option value="mrs">{t("userInfo.mrs")}</option>
              <option value="ms">{t("userInfo.ms")}</option>
            </select>
          </div>

          <div className="col-md-4">
            <label htmlFor="first-name" className="form-label font-weight-bold">
              {t("userInfo.firstName")}
            </label>
            <input
              type="text"
              id="first-name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className="form-control"
              required
            />
          </div>

          {/* Last Name field */}
          <div className="col-md-4">
            <label htmlFor="last-name" className="form-label font-weight-bold">
              {t("userInfo.lastName")}
            </label>
            <input
              type="text"
              id="last-name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className="form-control"
              required
            />
          </div>
        </div>

        <div className="mb-3">
          <label htmlFor="dateOfBirth" className="form-label font-weight-bold">
            {t("userInfo.dateOfBirth")}
          </label>
          <input
            type="date"
            id="dateOfBirth"
            value={dateOfBirth}
            onChange={(e) => setDob(e.target.value)}
            className="form-control"
            required
          />
          {dobError && <div className="text-danger">{dobError}</div>}
        </div>
        <div className="mb-3">
          <label htmlFor="phone-number" className="form-label font-weight-bold">
            {t("userInfo.phoneNumber")}
          </label>
          <PhoneInput
            international
            defaultCountry="GB"
            value={phoneNumber}
            onChange={setPhoneNumber}
            id="phone-number"
            required
          />
          {phoneNumberError && (
            <div className="text-danger">{phoneNumberError}</div>
          )}
        </div>

        {postStep3Error && postStep3Error.response && (
          <div className="text-danger mb-3">
            {postStep3Error && "An error occurred. Please try again later."}
          </div>
        )}

        <PrimaryButton
          type="submit"
          text={postStep3Loading ? null : t("userInfo.submit")}
          onClick={submitInfo}
          icon={postStep3Loading ? <CircularProgress /> : null}
          sx={{ width: "100%" }}
        />
      </form>
    </div>
  );
};

export default Step3UserInfo;
