import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const NoTransfer = ({ type }) => {
    const { t } = useTranslation();

    let mainTextKey, subTextKey;
    switch (type) {
        case 'active':
            mainTextKey = "transferList.noActiveTransfersMainText";
            subTextKey = "transferList.noActiveTransfersSubText";
            break;
        case 'past':
            mainTextKey = "transferList.noPastTransfersMainText";
            subTextKey = "transferList.noPastTransfersSubText";
            break;
        case 'permission':
            mainTextKey = "transferList.noPermissionMainText";
            subTextKey = "transferList.noPermissionSubText";
            break;
        default:
            mainTextKey = "transferList.noTransfersMainText";
            subTextKey = "transferList.noTransfersSubText";
            break;
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "70vh",
                padding: "2rem",
                backgroundColor: "#f5f5f5",
                borderRadius: "15px",
                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                maxWidth: "600px",
                margin: "auto",
                marginTop: "10vh",
            }}
        >
            <Typography
                variant="h4"
                align="center"
                mb={2}
                sx={{ fontWeight: "bold" }}
            >
                {t(mainTextKey)}
            </Typography>
            <Typography variant="body1" align="center" mb={4}>
                {t(subTextKey)}
            </Typography>
        </Box>
    );
};

export default NoTransfer;
