import React from 'react';
import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import featureFlags from '../../../featureFlags';

const NoInvoiceMessage = () => {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="50vh"
    >
      <Typography variant="h5" align="center" gutterBottom>
        {featureFlags.appointmentBooking ? t('noInvoice.title') : t('noInvoiceGarage.title')}
      </Typography>
      <Typography variant="body1" align="center">
        {featureFlags.appointmentBooking ? t('noInvoiceGarage.firstInfo') : t('noInvoice.firstInfo')}
      </Typography>
      <Typography variant="body1" align="center">
        {featureFlags.appointmentBooking ? t('noInvoiceGarage.secondInfo') : t('noInvoice.secondInfo')}
      </Typography>
    </Box>
  );
}

export default NoInvoiceMessage;
