import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Container, Skeleton, Box } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTranslation } from 'react-i18next';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { MdStar, MdKey, MdReceipt } from 'react-icons/md';
import OwnershipEventItem from './EventCard/OwnershipEventItem';
import AppointmentEventItem from './EventCard/AppointmentEventItem';
import InvoiceEventItem from './EventCard/InvoiceEventItem';
import { getHistoryEvents } from '../../../api';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const History = ({ vehicleUid, permissionUid = null }) => {
    const { t } = useTranslation();
    const token = useSelector((state) => state.auth.jwt);
    const API_URL = process.env.REACT_APP_API_URL;

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const [historyData, setHistoryData] = useState([]);
    const [getHistoryError, setGetHistoryError] = useState(null);
    const [getHistoryLoading, setGetHistoryLoading] = useState(true);

    useEffect(() => {
        const fetchHistoryData = async () => {
            setGetHistoryLoading(true);
            try {
                const data = await getHistoryEvents(vehicleUid, permissionUid)
                setHistoryData(data);
            } catch (error) {
                setGetHistoryError(error);
            } finally {
                setGetHistoryLoading(false);
            }
        };

        if (vehicleUid) {
            fetchHistoryData();
        }
    }, [vehicleUid, token, API_URL, permissionUid]);

    const eventComponentMapping = {
        OWNERSHIP: { component: OwnershipEventItem, icon: <MdKey />, color: 'rgb(33, 150, 243)' },
        APPOINTMENT: { component: AppointmentEventItem, icon: <MdStar />, color: 'rgb(233, 30, 99)' },
        INVOICE: { component: InvoiceEventItem, icon: <MdReceipt />, color: 'rgb(76, 175, 80)' },
    };

    if (getHistoryLoading) {
        return (
            <Container>
                <Typography variant="h5" sx={{ my: 3, textAlign: 'center' }}>
                    {t('history.title')}
                </Typography>
                <div style={{ overflowY: 'auto', maxHeight: '70vh' }}>
                    {[...new Array(3)].map((_, index) => (
                        <div key={index} style={{ marginBottom: '16px' }}>
                            <Skeleton variant="text" width="40%" height={30} animation="wave" />
                            <Skeleton variant="rectangular" height={118} animation="wave" />
                        </div>
                    ))}
                </div>
            </Container>
        );
    }

    if (getHistoryError) {
        return (
            <Container maxWidth="sm">
                <Box textAlign="center" p={4} sx={{ bgcolor: 'background.paper', mt: 8, borderRadius: 2, boxShadow: 3 }}>
                    <ErrorOutlineIcon color="error" style={{ fontSize: 60 }} />
                    <Typography variant="h6" color="error" gutterBottom>
                        {t('history.errorTitle')}
                    </Typography>
                    <Typography variant="body1" color="textSecondary" paragraph>
                        {t('history.error')}
                    </Typography>
                </Box>
            </Container>
        );
    }

    return (
        <Container>
            <Typography variant="h5" sx={{ my: 3, textAlign: 'center' }}>
                {t('history.title')}
            </Typography>
            <div style={{ overflowY: 'auto', maxHeight: '70vh' }}>

                {historyData && historyData.length === 0 ? (
                    <Typography variant="body1" color="textSecondary" sx={{ textAlign: 'center', my: 2 }}>
                        {t('history.noHistory')}
                    </Typography>
                ) : (
                    <VerticalTimeline>
                        {historyData.map((item, index) => {
                            const eventDate = new Date(item.eventDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
                            const { component: EventComponent, icon, color } = eventComponentMapping[item.eventType] || {};
                            return EventComponent ? (
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--education"
                                    key={index}
                                    contentStyle={{ background: '#EEEEEE', borderRadius: '20px' }}
                                    contentArrowStyle={{ borderRight: '7px solid  #EEEEEE' }}
                                    date={item.eventType === 'INVOICE' && isSmallScreen ? null : eventDate}
                                    icon={icon}
                                    iconStyle={{ background: color, color: '#fff', height: '40px', width: '40px' }}
                                >
                                    <EventComponent item={item} />
                                </VerticalTimelineElement>
                            ) : null;
                        })}
                    </VerticalTimeline>
                )}
            </div>

        </Container>
    );
};

export default History;
