import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Container, Badge } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import './TopBar.css';
import { useTranslation } from "react-i18next";
import AccountButton from './AccountButton';
import LoginButton from './LoginButton';
import MobileMenuOverlay from './MobileMenuOverlay';
import NotificationDropdown from './Notification/NotificationDropdown';

const TopBar = () => {
  const [isSidebarHidden, setIsSidebarHidden] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 992) {
        setIsSidebarHidden(true);
      } else {
        setIsSidebarHidden(false);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const isFullyOnboarded = useSelector((state) => state.auth.isFullyOnboarded);

  return (
    <>
      {isLoggedIn && isFullyOnboarded && (
        <MobileMenuOverlay isOpen={menuVisible} onClose={() => setMenuVisible(false)} />
      )}

      <Navbar collapseOnSelect expand="lg" variant="dark" className="topbar fixed-top">
        <Container>
          <Navbar.Brand as={Link} to="/" className="topbar-brand">
            <img src="/pitstop.png" alt="PitStop" className="logo-image" width="160" height="50" />
            {isLoggedIn &&<Badge bg="warning" text="dark" className="ms-2">{t("topBar.beta")}</Badge>}
          </Navbar.Brand>

          <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
            {isLoggedIn && <NotificationDropdown />}

            {isLoggedIn && (
              <AccountButton />
            )}
          </Navbar.Collapse>

          {isSidebarHidden && isLoggedIn && isFullyOnboarded ? (
            <div className="burger-and-bell-container">
              <NotificationDropdown />

              <div
                className={`burger-menu ${menuVisible ? 'cross' : ''}`}
                onClick={() => setMenuVisible((prevVisible) => !prevVisible)}
              >
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
              </div>
            </div>
          ) : null}

          {isSidebarHidden && !isLoggedIn ? (
            <LoginButton />
          ) : null}
        </Container>
      </Navbar>
    </>
  );
};

export default TopBar;
