import {
    FETCH_VEHICLES_FROM_USER_REQUEST,
    FETCH_VEHICLES_FROM_USER_SUCCESS,
    FETCH_VEHICLES_FROM_USER_FAILURE,
    FETCH_VEHICLE_ACTIVE_TRANSFERS_REQUEST,
    FETCH_VEHICLE_ACTIVE_TRANSFERS_SUCCESS,
    FETCH_VEHICLE_ACTIVE_TRANSFERS_FAILURE
} from '../actionTypes';

const initialState = {
    hasActiveTransfer: false,
    hasActiveTransferLoading: false,
    hasActiveTransferFailure: false,
    userVehiclesLoading: false,
    userVehicles: [],
    userVehiclesError: '',
    vehicleSpecificInformationLoading: false,
    vehicleSpecificInformation: [],
    vehicleSpecificInformationError: '',
};

const vehicleReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_VEHICLES_FROM_USER_REQUEST:
            return {
                ...state,
                userVehiclesLoading: true,
            };
        case FETCH_VEHICLES_FROM_USER_SUCCESS:

            return {
                ...state,
                userVehiclesLoading: false,
                userVehicles: action.payload,
                userVehiclesError: ''
            };
        case FETCH_VEHICLES_FROM_USER_FAILURE:
            return {
                ...state,
                userVehiclesLoading: false,
                userVehicles: [],
                userVehiclesError: action.payload,
            };

        // ------------------------------------------------------------------------

        case FETCH_VEHICLE_ACTIVE_TRANSFERS_REQUEST:
            return {
                ...state,
                hasActiveTransferLoading: true,
                hasActiveTransferFailure: false,
                hasActiveTransfer: false,
            };
        case FETCH_VEHICLE_ACTIVE_TRANSFERS_SUCCESS:

            const hasActiveTransfer = action.payload.length > 0;

            return {
                ...state,
                hasActiveTransferLoading: false,
                hasActiveTransferFailure: false,
                hasActiveTransfer: hasActiveTransfer,
            };
        case FETCH_VEHICLE_ACTIVE_TRANSFERS_FAILURE:
            return {
                ...state,
                hasActiveTransferLoading: false,
                hasActiveTransferFailure: true,
                hasActiveTransfer: false,
            };
        default:
            return state;
    }
};

export default vehicleReducer;
