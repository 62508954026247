import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Box, Card, CardActionArea, CardContent, CardMedia, Chip, Skeleton, Typography,} from "@mui/material";
import RegistrationPlate from "../../../../Global/RegistrationPlate";
import {fetchVehicleImage} from "../../../../../api";
import GlobalDetails from "../../../Global/GlobalDetails";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const MobileVehicleCard = ({vehicle}) => {
    const [imageData, setImageData] = useState("");
    const [imageLoaded, setImageLoaded] = useState(false);
    const [imageLoadError, setImageLoadError] = useState(false);
    const navigate = useNavigate();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

    const {
        id,
        identification,
        make,
        model,
        trim,
        type,
        year,
        color,
        fuelType,
        hasActiveTransfer,
    } = vehicle;

    useEffect(() => {
        if (!identification) return;
        (async () => {
            try {
                const imageDataBase64 = await fetchVehicleImage(identification);
                setImageData(imageDataBase64);
                setImageLoaded(true);
            } catch (error) {
                setImageLoadError(true);
            } finally {
                setImageLoaded(true);
            }
        })();
    }, [identification]);

    const handleCardClick = () => {
        navigate(`/vehicles/${id}`);
    };

    return (
        <Card sx={{bgcolor: "background.paper", boxShadow: 1}}>
            <CardActionArea onClick={handleCardClick}>
                {imageLoaded ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <CardMedia
                            component="img"
                            src={`data:image/jpeg;base64,${imageData}`}
                            alt={`${make} ${model}`}
                            onError={(e) => (e.target.src = "/pitstop.png")}
                            sx={{
                                objectFit: 'contain',
                                width: '80vw',
                                height: '25vh',
                                maxWidth: isSmallScreen ? '90vw' : '30vw',
                                maxHeight: isSmallScreen ? '25vh' : '30vh',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                display: 'block'
                            }}

                        />

                        {hasActiveTransfer && (
                            <Chip
                                label="Active Transfer Request"
                                size="medium"
                                sx={{
                                    position: "absolute",
                                    top: 16,
                                    left: 16,
                                    fontSize: "1rem",
                                    bgcolor: "#FFC107",
                                    color: "black",
                                    "& .MuiChip-label": {fontWeight: "bold"},
                                }}
                            />
                        )}
                    </div>
                ) : (
                    <Skeleton variant="rectangular" width="100%" height={300}/>
                )}

                <CardContent>
                    <Box
                        sx={{
                            padding: 2,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Typography
                            variant="h5"
                            component="div"
                            align="center"
                            gutterBottom
                        >
                            {`${make} ${model}`} {trim && ` - ${trim}`}
                        </Typography>
                    </Box>

                    <RegistrationPlate identification={identification}/>
                    <GlobalDetails
                        type={type}
                        yearOfManufacture={year}
                        fuelType={fuelType}
                        color={color}
                    />
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default MobileVehicleCard;
