import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';
import { AUTH_LOGIN, AUTH_LOGOUT, FETCH_USER_CLIENT_ONBOARDING_STATUS_SUCCESS } from './actionTypes';
import { IS_LOGGED_IN_KEY, IS_FULLY_ONBOARDED_KEY, USER_UID_KEY, USER_EMAIL_KEY } from '../utils/cookieConst';

function saveWithExpiry(key, value) {
  const now = new Date().getTime();
  const expiryTime = 6 * 24 * 60 * 60 * 1000; //6 days

  const item = {
    value: value,
    expiry: now + expiryTime,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

function getWithExpiry(key) {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date().getTime();
  if (now > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

const initialState = {
  auth: {
    isLoggedIn: getWithExpiry(IS_LOGGED_IN_KEY) === 'true',
    isFullyOnboarded: getWithExpiry(IS_FULLY_ONBOARDED_KEY) === 'true',
    userEmail: getWithExpiry(USER_EMAIL_KEY) || null,
    userUid: getWithExpiry(USER_UID_KEY) || null,
  },
};

const syncWithLocalStorage = store => next => action => {
  const result = next(action);
  if (action.type === AUTH_LOGOUT) {
    localStorage.removeItem(IS_LOGGED_IN_KEY);
    localStorage.removeItem(IS_FULLY_ONBOARDED_KEY);
    localStorage.removeItem(USER_UID_KEY);
    localStorage.removeItem(USER_EMAIL_KEY);
  } else if (action.type === AUTH_LOGIN) {
    saveWithExpiry(IS_LOGGED_IN_KEY, 'true');
    saveWithExpiry(IS_FULLY_ONBOARDED_KEY, action.isFullyOnboarded.toString());
    saveWithExpiry(USER_UID_KEY, action.userUid);
    saveWithExpiry(USER_EMAIL_KEY, action.userEmail);
  } else if (action.type === FETCH_USER_CLIENT_ONBOARDING_STATUS_SUCCESS) {
    if (action.payload.isFullyOnboarded) {
      saveWithExpiry(IS_FULLY_ONBOARDED_KEY, 'true');
    }
  }
  return result;
};

const middleware = [thunk, syncWithLocalStorage];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
);

export default store;
