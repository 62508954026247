import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import {onboardingStatusRequest} from "../../../redux/Registration/onboardingStatusActions";
import Step2EmailConfirmation from "./SignupSteps/Step2EmailConfirmation/Step2EmailConfirmation";
import Step3UserInfo from "./SignupSteps/Step3UserInfo/Step3UserInfo";
import Step5Address from "./SignupSteps/Step5Address/Step5Address";
import StepperComponent from "./SignupSteps/StepperComponent";
import Step4IDVerification from "./SignupSteps/Step4IDVerification/Step4IDVerification";
import "./PostCredentialsCheckForm.css";
import ErrorFallback from "../../Global/ErrorFallback";
import {useTranslation} from 'react-i18next';

const PostCredentialsCheckSignupForm = () => {
    const [step, setStep] = useState(1);

    const {t} = useTranslation();
    const userUid = useSelector((state) => state.auth.userUid);
    const email = useSelector((state) => state.auth.userEmail);
    const isFullyOnboarded = useSelector((state) => state.auth.isFullyOnboarded);
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const uid = queryParams.get("id");
    const dispatch = useDispatch();

    const {
        onboardingStatus,
        onboardingStatusLoading,
        onboardingStatusError
    } = useSelector((state) => state.onboardingStatus);

    useEffect(() => {
        if (isFullyOnboarded) {
            navigate("/vehicles/add");
        }
    }, [isFullyOnboarded, navigate]);

    useEffect(() => {
        const targetUid = uid || userUid;
        if (targetUid) {
            dispatch(onboardingStatusRequest(targetUid));
        }
    }, [uid, userUid, dispatch]);

    useEffect(() => {
        if (onboardingStatus) {
            if (!onboardingStatus.emailConfirmed) {
                setStep(2);
            } else if (!onboardingStatus.personalInformation) {
                setStep(3);
            } else if (!onboardingStatus.identityVerification) {
                setStep(4);
            } else if (!onboardingStatus.address) {
                setStep(5);
            }
        }
    }, [onboardingStatus]);

    if (onboardingStatusError) {
        return (
            <Box display="flex" alignItems="center" justifyContent="center" height="70vh">
                <ErrorFallback message={t("userInfo.errorFallback")}/>
            </Box>
        );
    }

    if (onboardingStatusLoading) {
        return (
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <CircularProgress/>
            </Box>
        );
    }

    const handleUserInfoSubmit = () => setStep(4);
    const handleIDVerificationSubmit = () => setStep(5);
    const handleBack = () => setStep(prevStep => prevStep > 2 ? prevStep - 1 : 2);

    return (
        <div className="post-signup-form-container">
            <StepperComponent activeStep={step - 2}/>
            {step === 2 && <Step2EmailConfirmation email={email}/>}
            {step === 3 && <Step3UserInfo handleUserInfoSubmit={handleUserInfoSubmit}/>}
            {step === 4 &&
                <Step4IDVerification handleIDVerificationSubmit={handleIDVerificationSubmit} handleBack={handleBack}/>}
            {step === 5 && <Step5Address handleBack={handleBack}/>}
        </div>
    );
};

export default PostCredentialsCheckSignupForm;
