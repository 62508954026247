import React from 'react';
import {Box, Card, CardContent, CardHeader, Divider, Typography, useMediaQuery, useTheme} from '@mui/material';
import {green, orange} from '@mui/material/colors';
import {useTranslation} from "react-i18next";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import RegistrationPlate from "../../Global/RegistrationPlate";

const TransferCard = ({transfer, status}) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const {t} = useTranslation();

    const getStatusIcon = (active) => {
        return active ? <PendingActionsIcon sx={{color: orange[500], mr: 1}}/> :
            <CheckCircleIcon sx={{color: green[500], mr: 1}}/>;
    };

    return (
        <Card sx={{
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            marginBottom: 2,
            alignItems: 'center',
            overflow: 'hidden',
            boxShadow: 3,
            position: 'relative'
        }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: status === 'active' ? orange[500] : green[500],
                width: 100, // Width of the square
                height: 100, // Height of the square
                m: 2,
                borderRadius: '0%', // This makes the shape a square instead of a circle
            }}>
                {getStatusIcon(status === 'active')}
                <Typography variant="h6" sx={{color: 'common.white', position: 'absolute'}}>
                    {status === 'active' ? "Pending" : "Completed"}
                </Typography>
            </Box>


            <Box sx={{
                display: 'flex',
                flexDirection: isSmallScreen ? 'column' : 'row',
                flex: 1,
                alignItems: 'center',
                paddingX: 2,
                width: '100%'
            }}>
                <Box sx={{width: isSmallScreen ? '100%' : '30%', textAlign: 'center'}}>
                    <img
                        src={transfer.imageData ? `data:image/jpeg;base64,${transfer.imageData}` : "path/to/default/image.jpg"}
                        alt="Vehicle"
                        style={{width: '100%', maxWidth: 300, objectFit: 'contain', marginBottom: 2}}
                    />
                    <RegistrationPlate identification={transfer.vehicle.identification}/>
                </Box>
                <Divider orientation={isSmallScreen ? "horizontal" : "vertical"} flexItem
                         sx={{my: isSmallScreen ? 1 : 0, mx: 2}}/>
                <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, paddingTop: isSmallScreen ? 1 : 0}}>
                    <CardHeader
                        title={<Typography
                            variant="h6">{`${transfer.recipient.firstName || ''} ${transfer.recipient.lastName || ''}`}</Typography>}
                        subheader={`${t("transfer.recipient")} ${transfer.recipient.email || 'N/A'}`}
                        sx={{paddingBottom: 0}}
                    />
                    <Divider variant="middle" sx={{my: 1}}/>
                    <CardContent sx={{display: 'flex', flexDirection: 'column', alignItems: 'start'}}>
                        <Typography
                            variant="body2">{t("transfer.vehicleId")} {transfer.vehicle.identification}</Typography>
                        <Typography variant="body2">{t("transfer.make")}: {transfer.vehicle.make}</Typography>
                        <Typography variant="body2">{t("transfer.model")}: {transfer.vehicle.model}</Typography>
                        <Typography variant="body2">{t("transfer.trim")}: {transfer.vehicle.trim}</Typography>
                        <Typography variant="body2">{t("transfer.color")}: {transfer.vehicle.color}</Typography>
                        <Typography
                            variant="body2">{t("transfer.dateFirstRegistered")}: {transfer.vehicle.firstRegistrationDate}</Typography>
                        <Typography variant="body2">{t("transfer.created")}: {transfer.creationDate}</Typography>

                        {transfer.completedAt && (
                            <Typography variant="body2">{t("transfer.completed")}: {transfer.completionDate}</Typography>
                        )}

                    </CardContent>
                </Box>
            </Box>
        </Card>
    );
};

export default TransferCard;
