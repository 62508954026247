import { getAllInvoicesForUserClient } from '../../api';
import {
    FETCH_INVOICES_USER_CLIENT_REQUEST,
    FETCH_INVOICES_USER_CLIENT_SUCCESS,
    FETCH_INVOICES_USER_CLIENT_FAILURE
} from '../actionTypes';

export const fetchInvoicesForClient = () => async (dispatch) => {
    try {
        dispatch({
            type: FETCH_INVOICES_USER_CLIENT_REQUEST,
        });

        const result = await getAllInvoicesForUserClient();

        dispatch({
            type: FETCH_INVOICES_USER_CLIENT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        dispatch({
            type: FETCH_INVOICES_USER_CLIENT_FAILURE,
            payload: error,
        });
    }
};
