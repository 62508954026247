import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    Box,
    CircularProgress,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Paper,
    Snackbar,
    SnackbarContent,
    Typography
} from '@mui/material';
import {ArrowBack} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';
import {onboardingConfirmIdUpload, getClientDocumentStatus, getIdPresignedUrlOnboarding, uploadFileToPresignedUrl} from '../../../../../api';
import {useNavigate} from 'react-router-dom';
import ErrorFallback from "../../../../Global/ErrorFallback";
import {onboardingStatusRequest} from "../../../../../redux/Registration/onboardingStatusActions";
import CustomDropzone from "../../../../Global/CustomDropzone";
import PrimaryButton from "../../../../Global/PrimaryButton";

const Step4IDVerification = ({handleIDVerificationSubmit, handleBack}) => {
    const {t} = useTranslation();
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadError, setUploadError] = useState("");
    const [uploadInProgress, setUploadInProgress] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [statusError, setStatusError] = useState(null);
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
    const userUid = useSelector((state) => state.auth.userUid);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const checkDocumentStatus = async () => {
            const response = await getClientDocumentStatus();
            if (response.status === 'APPROVED') {
                setStatusError(true);

                setTimeout(() => {
                    if (statusError) {
                        navigate(`/`);
                    }
                }, 4000);
            }
        };

        checkDocumentStatus();
    }, [navigate, statusError]);

    if (statusError) {
        return (
            <Box display="flex" alignItems="center" justifyContent="center" height="70vh">
                <ErrorFallback message={t("idVerification.alreadyVerifiedError")}/>
            </Box>
        )
    }

    const handleFileAccepted = (file) => {
        if (!file) {
            return;
        }

        const filePreviewUrl = URL.createObjectURL(file);
        setSelectedFile({
            file: file,
            previewUrl: filePreviewUrl,
            name: file.name
        });
    };

    const uploadID = async () => {
        if (!selectedFile) {
            setUploadError(t("idVerification.noFileError"));
            return;
        }

        try {
            setUploadInProgress(true);
            const {presignedUrl, documentId} = await getIdPresignedUrlOnboarding(selectedFile.name, selectedFile.type);
            await uploadFileToPresignedUrl(selectedFile.file, presignedUrl);
            await onboardingConfirmIdUpload(documentId);
            await dispatch(onboardingStatusRequest(userUid));
            if (typeof handleIDVerificationSubmit === 'function') {
                handleIDVerificationSubmit();
            }
        } catch (error) {
            console.log(error);
            setUploadError(t("idVerification.uploadError"));
        } finally {
            setUploadSuccess(true);
            setIsSnackbarOpen(true);
            setUploadInProgress(false);
            setTimeout(() => {
                if (typeof handleIDVerificationSubmit === 'undefined') {
                    navigate(`/`);
                }
            }, 4000);
        }
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", minHeight: '60vh'}}>
            <Paper elevation={3} sx={{maxWidth: '70vw', p: 4, m: 2, width: '100%'}}>
                <IconButton sx={{position: "absolute", top: 8, left: 8}} onClick={handleBack} aria-label="Go back">
                    <ArrowBack/>
                </IconButton>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={6}>
                        <Typography variant="h5" gutterBottom align="center">
                            {t("idVerification.header")}
                        </Typography>
                        <List sx={{listStyleType: 'disc', pl: 4}}>
                            <ListItem sx={{display: 'list-item'}}>
                                <ListItemText primary={t("idVerification.instruction1")}/>
                            </ListItem>
                            <ListItem sx={{display: 'list-item'}}>
                                <ListItemText primary={t("idVerification.instruction2")}/>
                            </ListItem>
                            <ListItem sx={{display: 'list-item'}}>
                                <ListItemText primary={t("idVerification.instruction3")}/>
                            </ListItem>
                            <ListItem sx={{display: 'list-item'}}>
                                <ListItemText primary={t("idVerification.instruction4")}/>
                            </ListItem>
                        </List>
                        <Typography variant="body2" align="center" sx={{mt: 1}}>
                            {t("idVerification.teamReviewNotice")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{textAlign: 'center'}}>
                        <Typography variant="h6" align="center" sx={{mb: 1}}>
                            {t("idVerification.exampleDocument")}
                        </Typography>
                        <img src="/example-driving-license.jpg" alt="Example Document"
                             style={{maxWidth: '100%', height: 'auto'}}/>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomDropzone
                            onFileAccepted={handleFileAccepted}
                            acceptedFiles={['image/jpeg', 'image/png', 'image/heic', 'image/heif', 'image/jpg']}
                            maxFiles={1}
                        />
                    </Grid>

                    {selectedFile && (
                        <Grid item xs={12} container spacing={2} justifyContent="center">
                            <Grid item>
                                <PrimaryButton
                                    disabled={uploadInProgress || uploadSuccess}
                                    icon={uploadInProgress || uploadSuccess ? <CircularProgress size={24}/> : null}
                                    text={uploadInProgress ? null : t("idVerification.submit")}
                                    onClick={uploadID}
                                    icon={uploadInProgress ? <CircularProgress size={24}/> : null}
                                />
                            </Grid>
                        </Grid>
                    )}
                    {uploadError && (
                        <Grid item xs={12}>
                            <Typography color="error">{uploadError}</Typography>
                        </Grid>
                    )}
                </Grid>
                <Snackbar open={isSnackbarOpen} autoHideDuration={3000} onClose={() => setIsSnackbarOpen(false)}
                          anchorOrigin={{vertical: "bottom", horizontal: "center"}}>
                    <SnackbarContent message={t("idVerification.uploadSuccess")}/>
                </Snackbar>
            </Paper>
        </Box>
    );
};

export default Step4IDVerification;
