import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    Backdrop,
    Box,
    Card,
    CardContent,
    CardMedia,
    CircularProgress,
    Fade,
    IconButton,
    MenuItem,
    Modal,
    Snackbar,
    TextField,
    Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import {useTranslation} from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {confirmInvoiceUpload, fetchInvoicePresignedUrl, uploadFileToPresignedUrl,} from "../../../api";
import {fetchInvoicesForClient} from "../../../redux/Invoice/actions";
import SecondaryButton from "../../Global/SecondaryButton";
import PrimaryButton from "../../Global/PrimaryButton";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} variant="filled" ref={ref} {...props} />;
});

const ManualUploadModal = ({open, onClose, identification, vehicleUid}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {userVehicles} = useSelector((state) => state.vehicles);

    const [serviceDate, setServiceServiceDate] = useState("");
    const [file, setFile] = useState(null);
    const [previewSrc, setPreviewSrc] = useState("");
    const [vehicleIdentification, setVehicleIdentification] = useState(
        identification || ""
    );
    const [vehicleId, setVehicleId] = useState(
        vehicleUid || ""
    );
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [uploadInProgress, setUploadInProgress] = useState(false);
    const [inputKey, setInputKey] = useState(Date.now());

    const today = new Date().toISOString().split("T")[0];

    const handleServiceDateChange = (e) => setServiceServiceDate(e.target.value);
    const handleVehicleIdentificationAndIdChange = (e) => {
        const {id, identification} = JSON.parse(e.target.value);
        setVehicleId(id);
        setVehicleIdentification(identification);
    }

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            if (["image/jpeg", "image/png"].includes(selectedFile.type)) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setPreviewSrc(reader.result);
                };
                reader.readAsDataURL(selectedFile);
            } else if (selectedFile.type === "application/pdf") {
                setPreviewSrc("pdf");
            } else {
                setFile(null);
                setPreviewSrc("");
                setErrorMessage(t("manualInvoiceUpload.invalidFileType"));
                setSnackbarOpen(true);
            }
        }
    };

    const removeSelectedFile = () => {
        setFile(null);
        setPreviewSrc("");
        setInputKey(Date.now()); // Reset the file input
    };

    const handleUpload = async () => {
        if (!file) {
            setErrorMessage(t("manualInvoiceUpload.selectFileFirst"));
            setSnackbarOpen(true);
            return;
        }
        setUploadInProgress(true);
        try {
            const {presignedUrl, invoiceId} = await fetchInvoicePresignedUrl(
                file.name,
                file.type,
                vehicleId
            );
            await uploadFileToPresignedUrl(file, presignedUrl, file.type);
            await confirmInvoiceUpload(
                {
                    fileName: file.name,
                    fileType: file.type,
                    serviceDate: serviceDate,
                    id: vehicleId,
                },
                invoiceId
            );
            await dispatch(fetchInvoicesForClient());
            onClose();
            window.location.reload();
        } catch (error) {
            setErrorMessage(t("manualInvoiceUpload.uploadFailed"));
            setSnackbarOpen(true);
        } finally {
            setUploadInProgress(false);
        }
    };

    const handleCloseSnackbar = () => setSnackbarOpen(false);

    return (
        <Modal
            open={open}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{timeout: 500}}
        >
            <Fade in={open}>
                <Box
                    sx={{
                        outline: "none",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: {xs: "100%", sm: "40%"},
                        maxWidth: {xs: "100%", sm: "40%"},
                        height: "auto",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                        overflowY: "auto",
                        maxHeight: {xs: "100%", sm: "90%"},
                        mx: {xs: 0, sm: 2},
                    }}
                >
                    <Box sx={{display: "flex", flexDirection: "column", gap: 2}}>
                        {vehicleIdentification ? (
                                <TextField
                                    text
                                    label={t("manualInvoiceUpload.vehicleIdentification")}
                                    value={vehicleIdentification}
                                    fullWidth
                                    required
                                    disabled
                                />
                        ) : (
                            <TextField
                                select
                                label={t("manualInvoiceUpload.vehicleIdentification")}
                                value={vehicleIdentification}
                                onChange={handleVehicleIdentificationAndIdChange}
                                fullWidth
                                required
                            >
                                {userVehicles.map((vehicle) => (
                                    <MenuItem
                                        key={vehicle.id}
                                        value={JSON.stringify({id: vehicle.id, identification: vehicle.identification})}
                                    >
                                        {`${vehicle.identification} - ${vehicle.make} ${vehicle.model}`}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                        <TextField
                            label={t("manualInvoiceUpload.date")}
                            type="date"
                            value={serviceDate}
                            onChange={handleServiceDateChange}
                            fullWidth
                            InputLabelProps={{shrink: true}}
                            inputProps={{max: today}}
                            required
                        />
                        <SecondaryButton
                            text={t("manualInvoiceUpload.selectFile")}
                            isFileInput={true}
                            onFileChange={handleFileChange}
                            inputAccept="image/jpeg,image/png,application/pdf"
                            inputKey={inputKey}
                        />
                        {previewSrc && (
                            <Card sx={{maxWidth: 345, mt: 2, position: "relative"}}>
                                {previewSrc === "pdf" ? (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            height: 140,
                                            bgcolor: "#f5f5f5",
                                        }}
                                    >
                                        <PictureAsPdfIcon sx={{fontSize: 60, color: "#e53935"}}/>
                                    </Box>
                                ) : (
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image={previewSrc}
                                        alt="Document preview"
                                    />
                                )}
                                <CardContent>
                                    <Typography variant="body2" color="text.secondary">
                                        {file ? file.name : ""}
                                    </Typography>
                                </CardContent>
                                <IconButton
                                    onClick={removeSelectedFile}
                                    size="small"
                                    sx={{position: "absolute", top: 0, right: 0}}
                                >
                                    <CloseIcon/>
                                </IconButton>
                            </Card>
                        )}
                        <Box
                            sx={{display: "flex", justifyContent: "space-between", mt: 2}}
                        >
                            <SecondaryButton
                                text={t("manualInvoiceUpload.cancel")}
                                sx={{width: "50%"}}
                                onClick={() => onClose()}
                            />

                            <PrimaryButton
                                text={uploadInProgress ? null : t("manualInvoiceUpload.upload")}
                                sx={{width: "50%"}}
                                disabled={uploadInProgress || !file}
                                onClick={() => handleUpload()}
                                icon={uploadInProgress ? <CircularProgress size={24}/> : null}
                            />
                        </Box>
                    </Box>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackbar}
                    >
                        <Alert onClose={handleCloseSnackbar} severity="error">
                            {errorMessage}
                        </Alert>
                    </Snackbar>
                </Box>
            </Fade>
        </Modal>
    );
};

export default ManualUploadModal;
