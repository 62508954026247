import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CircularProgress from "@mui/material/CircularProgress";
import "./Step1Credentials.css";
import PrimaryButton from "../../../../Global/PrimaryButton";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { postStep1Credentials } from "../../../../../api";

const Step1Credentials = ({ handleCredentialsSubmit, setEmailAddress }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm();
  const { t } = useTranslation();
  const location = useLocation();
  const emailParam = new URLSearchParams(location.search).get("email");

  const [loading, setLoading] = useState(false);
  const [backendError, setBackendError] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false); // State to manage checkbox

  useEffect(() => {
    if (emailParam) {
      setValue("email", emailParam);
    }
  }, [emailParam, setValue]);

  const onSubmit = async (data) => {
    if (!termsAccepted) {
      setBackendError("Please accept the terms and conditions.");

      return;
    }

    setLoading(true);
    try {
      data.termsAccepted = termsAccepted;

      await postStep1Credentials(data);

      setEmailAddress(data.email);

      handleCredentialsSubmit();
    } catch (error) {
      setBackendError(
        error.response?.data?.message || "An error occurred. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleTermsCheckboxChange = () => {
    setTermsAccepted(!termsAccepted);
    setBackendError(""); // Clear backend error when checkbox state changes
  };

  return (
    <div className="signup-form-container">
      <form onSubmit={handleSubmit(onSubmit)} className="signup-form">
        <h2 className="form-title">{t("signup.createAccount")}</h2>
        <div className="form-group">
          <label htmlFor="email" className="form-label">
            {t("signup.email")}:
          </label>
          <input
            type="email"
            id="email"
            {...register("email", { required: true })}
            defaultValue={emailParam || ""}
            className="form-control"
            disabled={!!emailParam}
          />
          {errors.email && (
            <span className="form-error">{t("signup.emailRequired")}</span>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="password" className="form-label">
            {t("signup.password")}:
          </label>
          <input
            type="password"
            id="password"
            {...register("password", {
              required: "Password is required",
              minLength: {
                value: 8,
                message: "Password must have at least 8 characters",
              },
            })}
            className="form-control"
          />
          {errors.password && (
            <span className="form-error">{errors.password.message}</span>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="confirmPassword" className="form-label">
            {t("signup.confirmPassword")}:
          </label>
          <input
            type="password"
            id="confirmPassword"
            {...register("confirmPassword", {
              validate: (value) =>
                value === watch("password") || "The passwords do not match",
            })}
            className="form-control"
          />
          {errors.confirmPassword && (
            <span className="form-error">{errors.confirmPassword.message}</span>
          )}
        </div>

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={termsAccepted}
                onChange={handleTermsCheckboxChange}
              />
            }
            label={
              <span
                dangerouslySetInnerHTML={{ __html: t("signup.acceptTerms") }}
              />
            }
          />
        </FormGroup>

        {backendError && <div className="form-error">{backendError}</div>}

        <div className="form-actions">
          {loading ? (
            <CircularProgress className="loading-indicator" />
          ) : (
            <PrimaryButton
              type="submit"
              text={t("signup.signUp")}
              disabled={!termsAccepted}
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default Step1Credentials;
