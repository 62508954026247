import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslations from './en.json';
import frTranslations from './fr.json';

i18n
    .use(LanguageDetector, {
        order: ['navigator'],  // Only use navigator
        checkWhitelist: true
    })
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: enTranslations
            },
            fr: {
                translation: frTranslations
            }
        },
        load: 'languageOnly', 
        checkWhitelist: true, 
        whitelist: ['en'],
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false
        }
    });



export default i18n;
