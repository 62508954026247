import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Typography, Modal, CircularProgress } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Skeleton from 'react-loading-skeleton';
import ErrorFallback from '../../../Global/ErrorFallback';

const MaintenanceQualityScore = () => {
    const { vehicleUid } = useParams();
    const { t } = useTranslation();

    const [maintenanceScore] = useState(80); // Fake score for placeholder
    const [maintenanceScoreError, setMaintenanceScoreError] = useState(null);
    const [maintenanceScoreLoading, setMaintenanceScoreLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const getColor = (score) => {
        if (score < 40) return '#f44336'; // Red
        if (score < 70) return '#ffc107'; // Yellow
        return '#4caf50'; // Green
    };

    if (maintenanceScoreError) {
        return <ErrorFallback message={t('vehicleDetails.error')} />;
    }

    if (maintenanceScoreLoading) {
        return <Skeleton variant="rectangular" width={210} height={118} />;
    }

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const modalBody = (
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4
        }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                {t('maintenanceScore.title')}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {t('maintenanceScore.explanation')}
            </Typography>
        </Box>
    );

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6" sx={{ mr: 1 }}>
                    {t('maintenanceScore.title')}
                </Typography>
                <IconButton onClick={handleOpenModal} aria-label="info">
                    <InfoIcon />
                </IconButton>
            </Box>
            <Box sx={{ position: 'relative', display: 'inline-flex', mt: 2 }}>
                <CircularProgress
                    variant="determinate"
                    value={maintenanceScore}
                    thickness={5}
                    size={140}
                    sx={{
                        color: getColor(maintenanceScore),
                        'circle': {
                            strokeLinecap: 'round'
                        }
                    }}
                />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: getColor(maintenanceScore)
                    }}
                >
                    <Typography variant="h3" component="div" color="textPrimary">
                        {`${maintenanceScore}%`}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        bgcolor: 'rgba(255, 255, 255, 0.8)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#000',
                        zIndex: 10
                    }}
                >
                    <Typography variant="h6" component="div" sx={{ color: 'grey.800', textAlign: 'center' }}>
                        {t('maintenanceScore.comingSoon')}
                    </Typography>
                </Box>
            </Box>
            <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {modalBody}
            </Modal>
        </Box>
    );
};

export default MaintenanceQualityScore;
