import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, Typography, Container } from '@mui/material';
import VehicleDetails from '../../Vehicle/SingleVehiclePage/VehicleDetails';
import { fetchSharedHistoryDetails } from '../../../api';

const ConsultSharedHistory = () => {
    const { vehicleUid, permissionUid } = useParams();
    const { t } = useTranslation();

    const [vehicleSharedHistoryInformation, setVehicleSharedHistoryInformation] = useState(null);
    const [vehicleSharedHistoryInformationError, setVehicleSharedHistoryInformationError] = useState(null);
    const [vehicleSharedHistoryInformationLoading, setVehicleSharedHistoryInformationLoading] = useState(true);

    useEffect(() => {
        const fetchVehicleData = async () => {
            try {
                const vehicleTechnicalInformationResponse = await fetchSharedHistoryDetails(vehicleUid, permissionUid);
                setVehicleSharedHistoryInformation(vehicleTechnicalInformationResponse);
            } catch (error) {
                setVehicleSharedHistoryInformationError(true);
            } finally {
                setVehicleSharedHistoryInformationLoading(false);
            }
        };

        fetchVehicleData();
    }, [vehicleUid]);

    if (vehicleSharedHistoryInformationLoading) {
        return (
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
                <CircularProgress />
                <Typography variant="h6" mt={2}>
                    {t('consultSharedHistory.loadingHistory')}
                </Typography>
            </Box>
        );
    }

    if (vehicleSharedHistoryInformation) {
        const owner = vehicleSharedHistoryInformation.currentOwner;

        return (
            <Container maxWidth="lg">
                <Box py={4} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <Typography variant="subtitle1" textAlign="center" color="textSecondary">
                        {`${owner.firstName.charAt(0).toUpperCase() + owner.firstName.slice(1)} ${owner.lastName.charAt(0).toUpperCase() + owner.lastName.slice(1)}` + ' ' +t('consultSharedHistory.title')}
                    </Typography>
                    <Typography variant="h4" textAlign="center">

                    </Typography>
                    <Typography variant="h5" textAlign="center" color="primary" sx={{ mt: 1 }}>
                        {vehicleSharedHistoryInformation.vehicleSpecificInformation.makeModel}
                    </Typography>
                </Box>
                <VehicleDetails
                    vehicleSpecificInformation={vehicleSharedHistoryInformation.vehicleSpecificInformation}
                    vehicleSpecificInformationLoading={vehicleSharedHistoryInformationLoading}
                    vehicleSpecificInformationError={vehicleSharedHistoryInformationError}
                    vehicleOwnerHistory={vehicleSharedHistoryInformation.vehicleOwnerHistory}
                    vehicleOwnerHistoryLoading={vehicleSharedHistoryInformationLoading}
                    vehicleOwnerHistoryError={vehicleSharedHistoryInformationError}
                    vehicleTechnicalInformation={vehicleSharedHistoryInformation.vehicleTechnicalInformation}
                    vehicleTechnicalInformationLoading={vehicleSharedHistoryInformationLoading}
                    vehicleTechnicalInformationError={vehicleSharedHistoryInformationError}
                    withActions={false}
                    permissionUid={permissionUid}
                />
            </Container>
        );
    }
};

export default ConsultSharedHistory;
