import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ErrorFallback from '../../Global/ErrorFallback';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import { Box, Card, CardContent, CardMedia, Container, Grid, Typography, CircularProgress, Paper } from '@mui/material';
import RegistrationPlate from '../../Global/RegistrationPlate';
import { fetchTransferRequest, fetchVehicleTransferSenderInformation, fetchVehicleImage, fetchVehicleBasicInformation, acceptVehicleTransfer } from '../../../api';
import './AcceptVehicleTransfer.scss';

const AcceptVehicleTransfer = () => {
  const { t } = useTranslation();
  const [vehicleInfo, setVehicleInfo] = useState(null);
  const [transferRequest, setTransferRequest] = useState(null);
  const [vehicleTransferSenderInformation, setVehicleTransferSenderInformation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { vehicleUid } = useParams();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageLoadError, setImageLoadError] = useState(false);
  const [imageUrl, setImage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    fetchTransferRequest(vehicleUid)
      .then(status => {
        setTransferRequest(status);
        if (status === 200) {
          Promise.all([fetchVehicleBasicInformation(vehicleUid), fetchVehicleTransferSenderInformation(vehicleUid)])
            .then(([vehicleData, transferInformation]) => {
              setVehicleInfo(vehicleData);
              setVehicleTransferSenderInformation(transferInformation);
            })
            .catch((error) => {
              console.error('Error fetching data:', error);
              setError(true);
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error('Error fetching transfer request:', error);
        setError(true);
        setLoading(false);
      });
  }, [vehicleUid]);

  useEffect(() => {
    (async () => {
      try {
        const imageResponse = await fetchVehicleImage(vehicleInfo.identification);
        setImage(imageResponse.length > 0 ? imageResponse[0].ImageUrl : '/pitstop.png');
      } catch (error) {
        setImageLoadError(error);
      } finally {
        setImageLoaded(true);
      }
    })();

  }, [vehicleInfo]);

  const handleImageError = (e) => {
    e.target.onerror = null;
    e.target.src = imageUrl;
  };

  const acceptTransfer = async () => {
    await acceptVehicleTransfer(vehicleUid);

    navigate('/');
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <ErrorFallback message="An error occurred. Please try again later" />;
  }

  return (
    <Container maxWidth="md">

      <Box display="flex" justifyContent="center" mb={3}>
        {vehicleTransferSenderInformation ? (
          <Box display="flex" justifyContent="center" mb={3}>
          <Paper elevation={3} sx={{ width: '100%', marginTop:'10vh', bgcolor: 'primary.main', color: 'primary.contrastText', p: 2 }}>
            <Typography variant="h5" align="center">
              {`${vehicleTransferSenderInformation.firstName} ${vehicleTransferSenderInformation.lastName} ${t('vehicleTransfer.requestTitle')}`}
            </Typography>
          </Paper>
        </Box>
        ) : (
          <CircularProgress />
        )}
      </Box>

      <div>
        {transferRequest && vehicleInfo ? (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h5">{`${vehicleInfo.make} ${vehicleInfo.model} ${vehicleInfo.trim}, ${vehicleInfo.yearOfManufacture}`}</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {imageUrl ? (
                        <>
                          <CardMedia
                            key={imageUrl}
                            component="img"
                            image={imageUrl}
                            alt={vehicleInfo.make}
                            style={{ objectFit: 'cover', maxWidth: '600px', maxHeight: '300px', display: imageLoaded ? 'block' : 'none' }}
                            onError={handleImageError}
                            onLoad={() => setImageLoaded(true)}
                          />
                          {!imageLoaded && (
                            <Skeleton width={600} height={300} />
                          )}
                        </>
                      ) : (
                        <Skeleton width={600} height={300} />
                      )}
                    </Grid>
                    <RegistrationPlate identification={vehicleInfo.identification} />
                    <Grid item xs={12}>
                      <Typography variant="body1">{`${t('vehicleTransfer.vehicleClass')}: ${vehicleInfo.vehicleClass}`}</Typography>
                      <Typography variant="body1">{`${t('vehicleTransfer.fuelType')}: ${vehicleInfo.fuelType}`}</Typography>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                          <Typography variant="body1">{`${t('vehicleTransfer.color')}: ${vehicleInfo.color}`}</Typography>
                        </Grid>
                        <Grid item>
                          <Box sx={{
                            width: '20px',
                            height: '20px',
                            backgroundColor: vehicleInfo.color.toLowerCase(),
                            border: '1px solid #ccc'
                          }} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} container justifyContent="center" alignItems="center" style={{ minHeight: '100%' }}>
              <button className="transfer-button" onClick={acceptTransfer}>{t('vehicleTransfer.transferButton')}</button>
            </Grid>
            <div className="empty-div"></div>
          </Grid>
        ) : (
          <ErrorFallback message={t('common:errorOccurred')} />
        )}
      </div>

    </Container>

  );
};

export default AcceptVehicleTransfer;
