import { getUserClientOnboardingStatus } from '../../api';
import {
    FETCH_USER_CLIENT_ONBOARDING_STATUS_REQUEST,
    FETCH_USER_CLIENT_ONBOARDING_STATUS_SUCCESS,
    FETCH_USER_CLIENT_ONBOARDING_STATUS_FAILURE,
} from '../actionTypes';

export const onboardingStatusRequest = () => async (dispatch) => {
    try {
        dispatch({
            type: FETCH_USER_CLIENT_ONBOARDING_STATUS_REQUEST,
        });

        const result = await getUserClientOnboardingStatus();

        dispatch({
            type: FETCH_USER_CLIENT_ONBOARDING_STATUS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        dispatch({
            type: FETCH_USER_CLIENT_ONBOARDING_STATUS_FAILURE,
            payload: error,
        });
    }
};
