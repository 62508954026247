import React from 'react';
import { Button } from '@mui/material';

const PrimaryButton = ({ onClick, disabled, text, icon, isFileInput, onFileChange, inputAccept, inputKey, sx, type = 'button' }) => {
  if (isFileInput) {
    return (
      <Button
        component="label" // Makes the button act as a label for the hidden file input
        variant="contained"
        disabled={disabled}
        type={type}
        sx={{
          backgroundColor: "var(--color-main)",
          padding: "10px 24px",
          "&:hover": {
            backgroundColor: "var(--color-secondary)",
          },
          ...sx // Merge with provided sx styles
        }}
      >
        {icon}
        {text}
        <input
          type="file"
          hidden
          onChange={onFileChange}
          accept={inputAccept}
          key={inputKey}
        />
      </Button>
    );
  }

  return (
    <Button
      variant="contained"
      onClick={onClick}
      disabled={disabled}
      startIcon={icon}
      type={type} 
      sx={{
        backgroundColor: "var(--color-main)",
        padding: "10px 24px",
        "&:hover": {
          backgroundColor: "var(--color-secondary)",
        },
        ...sx // Merge with provided sx styles
      }}
    >
      {text}
    </Button>
  );
};

export default PrimaryButton;
