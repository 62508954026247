import React, { useEffect, useState } from 'react';
import { fetchVehicleImage } from '../../../../api';
import { Grid, Skeleton } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ErrorFallback from "../../../Global/ErrorFallback";
import { useTranslation } from "react-i18next";

const VehicleImage = ({ vehicleIdentification }) => {
    const [imageData, setImageData] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const { t } = useTranslation();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        (async () => {
            try {
                const imageDataBase64 = await fetchVehicleImage(vehicleIdentification);
                setImageData(imageDataBase64);
            } catch (error) {
                setError(true);
            } finally {
                setLoading(false);
            }
        })();
    }, [vehicleIdentification]);

    if (loading) return <Skeleton variant="rectangular" width={isSmallScreen ? '90vw' : '30vw'} height={200} />;
    if (error) return <ErrorFallback message={t("userInfo.errorFallback")} />;

    return (
        <Grid item xs={12}>
            <img src={`data:image/jpeg;base64,${imageData}`} alt="Vehicle" style={{ width: isSmallScreen ? '90vw' : '30vw', maxWidth: '100%', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
        </Grid>
    );
};

export default VehicleImage;
