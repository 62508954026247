import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Typography, Alert, AlertTitle } from "@mui/material";
import MotRecordCard from "./MotRecordCard";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTranslation } from "react-i18next";
import ErrorFallback from "../../../Global/ErrorFallback";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { getMotInformation } from "../../../../api";

const MotInfoCard = ({ vehicleUid }) => {
    const [motHistory, setMotHistory] = useState([]);
    const [motHistoryLoading, setMotHistoryLoading] = useState(false);
    const [motHistoryError, setMotHistoryError] = useState(false);
    const [vehicleHasCurrentMot, setVehicleHasCurrentMot] = useState(false);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();

    useEffect(() => {
        const fetchMotInfo = async () => {
            try {
                setMotHistoryLoading(true);
                const response = await getMotInformation(vehicleUid);
                const motData = response.data;
                setMotHistory(motData.MotHistory.RecordList);
                setVehicleHasCurrentMot(motData.VehicleStatus.VehicleHasCurrentMot);
                setMotHistoryLoading(false);
            } catch (error) {
                setMotHistoryError(true);
                setMotHistoryLoading(false); // Ensure loading state is reset
            }
        };

        fetchMotInfo();
    }, [vehicleUid]);

    const style = {
        height: '50vh',
        overflowY: "auto",
        bgcolor: "background.paper",
        m: 2,
        p: 3,
        boxShadow: 3,
    };

    return (
        <Box sx={style}>
            <Typography variant="h6" component="h2" gutterBottom>
                <Box
                    sx={{
                        display: "inline-flex",
                        alignItems: "center",
                        bgcolor: vehicleHasCurrentMot ? "success.main" : "error.main",
                        color: "#fff",
                        borderRadius: "20px",
                        p: 0.5,
                        pl: 1,
                        pr: 1,
                    }}
                >
                    {/* Show loading spinner when data is loading */}
                    {motHistoryLoading && <CircularProgress color="inherit" size={24} />}

                    {/* Show MOT status only if not loading and no error */}
                    {!motHistoryLoading && !motHistoryError && (
                        vehicleHasCurrentMot ? (
                            <>
                                <CheckCircleIcon />
                                <Box component="span" sx={{ ml: 1 }}>
                                    {t('motInfoModal.currentMOT')}
                                </Box>
                            </>
                        ) : (
                            <>
                                <CancelIcon />
                                <Box component="span" sx={{ ml: 1 }}>
                                    {t('motInfoModal.noMOT')}
                                </Box>
                            </>
                        )
                    )}
                </Box>
            </Typography>

            <Box>
                {/* Show error message if there is an error */}
                {motHistoryError && (
                    <Alert severity="error">
                        <AlertTitle>{t("userInfo.errorFallback")}</AlertTitle>
                    </Alert>
                )}

                {/* Show no records message if no MOT history is found */}
                {!motHistoryError && motHistory.length === 0 && !motHistoryLoading && (
                    <Alert severity="info">
                        <AlertTitle>{t('motInfoModal.noRecordsTitle')}</AlertTitle>
                        {t('motInfoModal.noRecords')}
                    </Alert>
                )}

                {/* Show MOT records */}
                {!motHistoryError && motHistory.length > 0 && motHistory.map((record, index) => (
                    <MotRecordCard
                        key={index}
                        motRecord={record}
                        mileageAnomaly={record.MileageAnomalyDetected}
                    />
                ))}
            </Box>
        </Box>
    );
};

export default MotInfoCard;
