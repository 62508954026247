import React, { useEffect, useState } from "react";
import { Alert, Box, Snackbar, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getOfficalVehicleInformation } from "../../../api";
import LoadingSkeleton from "../../Global/LoadingSkeleton";
import PrimaryButton from "../../Global/PrimaryButton";

function AddVehicleForm({ setStep, setVehicleData }) {
    const { t } = useTranslation();

    const [officialVehicleDataLoading, setOfficialVehicleDataLoading] = useState(false);
    const [officialVehicleDataError, setOfficialVehicleDataError] = useState(false);
    const [vehicleIdentification, setVehicleIdentification] = useState("");
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackBarErrorMessage, setSnackBarErrorMessage] = useState("");

    useEffect(() => {
        if (officialVehicleDataError && [404, 400].includes(officialVehicleDataError?.response?.status)) {
            setSnackBarErrorMessage(
                t(officialVehicleDataError.response.status === 404 ? "addVehicleForm.vehicleNotFound" : "addVehicleForm.invalidVehicleIdentification")
            );
            setShowSnackbar(true);
        }
    }, [officialVehicleDataError, t]);

    const handleVehicleIdentificationChange = (e) => {
        const valueWithoutSpaces = e.target.value.replace(/\s+/g, '');
        setVehicleIdentification(valueWithoutSpaces);
    };

    const handleFetchVehicleData = async (e) => {
        e.preventDefault(); // Prevent default form submission

        setOfficialVehicleDataLoading(true);

        try {
            const response = await getOfficalVehicleInformation(vehicleIdentification);
            setVehicleData(response.data);
            setOfficialVehicleDataError(false);
            setStep(2);
        } catch (error) {
            setOfficialVehicleDataError(error);
        } finally {
            setOfficialVehicleDataLoading(false);
        }
    };

    return (
        <form
            onSubmit={handleFetchVehicleData} // Call handleFetchVehicleData when the form is submitted
            style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "5vh",
            }}
        >
            {officialVehicleDataLoading ? (
                <Box sx={{ width: '60vw' }}>
                    <LoadingSkeleton withImage={true} rows={3} />
                </Box>
            ) : (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "20px",
                        maxWidth: "400px",
                        width: "90%",
                        padding: "2em",
                        borderRadius: "4px",
                        boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                    }}
                >
                    <h2>{t("addVehicleForm.findVehicle")}</h2>
                    <TextField
                        fullWidth
                        variant="outlined"
                        value={vehicleIdentification}
                        onChange={handleVehicleIdentificationChange}
                        placeholder={t("addVehicleForm.vehicleIdentification")}
                        inputProps={{
                            style: { textAlign: 'center' }
                        }}
                    />
                    <PrimaryButton
                        type="submit"
                        text={t("addVehicleForm.next")}
                        disabled={!vehicleIdentification.trim()}
                    />
                </div>
            )}
            <Snackbar open={showSnackbar} autoHideDuration={5000} onClose={() => setShowSnackbar(false)}>
                <Alert onClose={() => setShowSnackbar(false)} severity="error" sx={{ width: "100%" }}>
                    {snackBarErrorMessage}
                </Alert>
            </Snackbar>
        </form>
    );
}

export default AddVehicleForm;
