import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {authLogout} from '../../../redux/authActions';

export function LogoutForm() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(authLogout()).then(() => {
            window.location.href = '/login';
        });
    }, [dispatch]);
}

export default LogoutForm;
