import React, {useCallback, useState} from "react";
import {useDropzone} from "react-dropzone";
import {useTranslation} from "react-i18next";
import {
    confirmVehicleProofOfOwnershipUpload,
    fetchVehicleProofOfOwnershipPresignedUrl,
    finaliseVehicleProofOfOwnershipUpload,
    uploadFileToPresignedUrl,
} from "../../../../api";
import {Box, CircularProgress, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ErrorFallback from "../../../Global/ErrorFallback";
import Disclaimer from "./Disclaimer";
import AcceptableProofofOwnershipList from "./AcceptableProofofOwnershipList";
import ProofDropzone from "./ProofDropzone";
import SecondaryButton from "../../../Global/SecondaryButton";
import PrimaryButton from "../../../Global/PrimaryButton";

const UploadProofOfOwnership = ({setStep, onBack, vehicleUid}) => {
    const {t} = useTranslation();
    const [uploadInProgress, setUploadInProgress] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploadVehicleMediaError, setUploadVehicleMediaError] = useState(false);
    const [showMaxFilesAlert, setShowMaxFilesAlert] = useState(false); // State to control the maximum files alert

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const dropzoneWidth = isSmallScreen ? "50vw" : "20vh";

    const onDrop = useCallback(
        (acceptedFiles) => {
            // Ensure maximum of 3 files are selected
            if (selectedFiles.length + acceptedFiles.length > 3) {
                setShowMaxFilesAlert(true); // Show alert if attempting to select more than 3 files
                return;
            }

            setSelectedFiles((prevFiles) =>
                [...prevFiles, ...acceptedFiles].slice(0, 3)
            );
        },
        [selectedFiles]
    );

    const onConfirm = async () => {
        try {
            setUploadInProgress(true);

            if (selectedFiles.length > 0) {
                for (const file of selectedFiles) {
                    await handleFileUpload(file);
                }
            }

            await finaliseVehicleProofOfOwnershipUpload(vehicleUid);

            setUploadInProgress(false);
            setStep(4);
        } catch (error) {
            setUploadVehicleMediaError(true);
            setUploadInProgress(false);
        }
    };

    const removeFile = (fileName) => {
        setSelectedFiles((prevFiles) =>
            prevFiles.filter((file) => file.name !== fileName)
        );
    };

    const handleFileUpload = async (file) => {
        if (file) {
            const {presignedUrl, key} = await fetchVehicleProofOfOwnershipPresignedUrl(
                file.name,
                file.type,
                vehicleUid
            );

            await uploadFileToPresignedUrl(file, presignedUrl);
            await confirmVehicleProofOfOwnershipUpload(key, vehicleUid);

            setUploadInProgress(false);
        }
    };

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

    return (
        <>
            {uploadVehicleMediaError ? (
                <ErrorFallback message={t("uploadProofOfOwnership.error")}/>
            ) : (
                <>
                    <Disclaimer isSmallScreen={isSmallScreen}/>
                    <Box
                        display="flex"
                        flexDirection={{xs: "column-reverse", md: "row"}}
                        alignItems="center"
                    >
                        <AcceptableProofofOwnershipList/>

                        <div>
                            <ProofDropzone
                                getRootProps={getRootProps}
                                getInputProps={getInputProps}
                                isDragActive={isDragActive}
                                selectedFiles={selectedFiles}
                                removeFile={removeFile}
                                dropzoneWidth={dropzoneWidth}
                            />
                            {selectedFiles.length === 3 && (
                                <Typography variant="body2" color="error" align="center">
                                    {t("uploadProofOfOwnership.maxFilesAlert")}
                                </Typography>
                            )}
                        </div>
                    </Box>

                    <Box
                        textAlign="center"
                        sx={{mt: '4vh', w: '100%'}}
                    >
                        <SecondaryButton
                            onClick={onBack}
                            text={t("uploadProofOfOwnership.back")}
                        />
                        <PrimaryButton
                            onClick={onConfirm}
                            text={t("uploadProofOfOwnership.confirm")}
                            disabled={selectedFiles.length === 0 || uploadInProgress}
                            icon={uploadInProgress ? <CircularProgress size={24}/> : null}

                        />
                    </Box>
                </>
            )}
        </>
    );
};

export default UploadProofOfOwnership;
