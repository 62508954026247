import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import './AccountButton.css';
import { useTranslation } from 'react-i18next';

const AccountButton = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (path) => {
    navigate(path);
    handleClose();
  };

  return (
    <>
      <Button
        variant="contained"
        size="large"
        className="account-button"
        onClick={handleButtonClick}
      >
        <FontAwesomeIcon icon={faUser} />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem onClick={() => handleMenuClick('/account')}>{t('accountButton.profile')}</MenuItem>
        <MenuItem onClick={() => handleMenuClick('/logout')}>{t('accountButton.logout')}</MenuItem>
      </Menu>
    </>
  );
};

export default AccountButton;
