import React, {useEffect, useState} from 'react';
import {CircularProgress, Button, Box} from '@mui/material';
import {Document, Page, pdfjs} from 'react-pdf';
import {useTheme} from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFViewer = ({fileData, numPages, onLoadSuccess}) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [currentPage, setCurrentPage] = useState(1);
    const { t } = useTranslation();

    const goToPrevPage = () => setCurrentPage(prev => Math.max(prev - 1, 1));
    const goToNextPage = () => setCurrentPage(prev => Math.min(prev + 1, numPages));

    const handleLoadSuccess = ({numPages}) => {
        onLoadSuccess(numPages);
    };

    useEffect(() => {
        if (numPages === 1) {
            setCurrentPage(1);  // Reset to first page if only one page is available
        }
    }, [numPages]);

    return (

        <>
            <Document file={fileData} onLoadSuccess={handleLoadSuccess} loading={<CircularProgress/>}>
                <Page
                    pageNumber={currentPage}
                    scale={isSmallScreen ? 0.6 : 0.81}
                />
            </Document>
            {numPages > 1 && (
                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 2}}>
                    <Button onClick={goToPrevPage} disabled={currentPage === 1}>
                        {t("pdfViewer.previous")}
                    </Button>
                    <Button onClick={goToNextPage} disabled={currentPage === numPages}>
                        {t("pdfViewer.next")}
                    </Button>
                </Box>
            )}
        </>
    );
};

export default PDFViewer;
