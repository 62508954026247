import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '80vh',
          textAlign: 'center',
          p: 3,
        }}
      >
        <Typography variant="h4" sx={{ fontSize: { xs: '2rem', sm: '3rem' }, mb: 2 }}>
          {t('notFound.title')}
        </Typography>
        <Typography variant="body1">
          {t('notFound.message')}
        </Typography>
      </Box>
    </Container>
  );
};

export default NotFound;
